import React from 'react'
import commonConstants from '../../../utils/constants'

const ContactUsInfo = ({ title }) => {
    return (
        <div >
            <div data-aos="fade-right" className='flex flex-col gap-2'>
                <div className='text-[27px] leading-[34px] font-[700] mb-5'>{title}</div>
                <div className='flex gap-3 items-center'>
                    <img src='/svg/call.svg' alt='call'/>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[33px] text-[18px] color-[#171717]' href={`tel:${commonConstants.company_number}`}>
                        {commonConstants.company_number}
                    </a>
                </div>
                <div data-aos="fade-right" className='flex gap-3 items-center'>
                    <img src='/svg/mail.svg' alt='mail'/>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[33px] text-[18px] color-[#171717]' href={`mailto:${commonConstants.company_email}`}>
                        {commonConstants.company_email}
                    </a>
                </div>
                <div data-aos="fade-right" className='flex gap-3 items-start max-w-[350px]'>
                    <img src='/svg/location.svg' className='animate-pulse' alt='location'/>
                    <span className='font-[500] leading-[33px] text-[18px] color-[#171717]'>
                        {commonConstants.company_address}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ContactUsInfo

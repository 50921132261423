import React from 'react'

const ProjectDescription = ({ service }) => {
    return (
        <>
            {service?.projectDevelopment?.map((project) => {
                return (
                    <div className={`flex ${project.flex}  my-[100px] gap-[8%]`}>
                        <div data-aos="fade-right" className='relative w-[100%]  h-max'>
                            <img data-aos="fade-right" className={`w-[100%] h-[577px] ${project.borderRadius} object-cover`} src={project.images} alt='' />
                            <div className='text-[18px] md:text-[24px] min-w-[220px] flex items-center justify-around gap-3 leading-[26px] font-[500] absolute right-[10%] bottom-[-60px] bg-white/60 [backdrop-filter:_blur(6px)] rounded-[20px] py-7 px-4 w-[60%]'>
                                <img src='/svg/achievement.svg' alt='' />
                                100% Customer
                                Satisfaction
                            </div>
                        </div>
                        <div className='flex flex-col gap-8  w-auto '>
                            {/* <span data-aos="fade-left" className='text-[23px] w-max leading-[35px] font-[400] px-[20px] py-[10px] bg-[#00000012] rounded-[80px]'>Why choose our services</span> */}
                            <span data-aos="fade-right" className='w-[90%] text-[33px] leading-[41px] font-[700]'>
                                {project.title}
                            </span>
                            <span data-aos="fade-right" className='w-[99%] text-[16px] md:text-[21px] leading-[27px] md:leading-[35px] font-[400]'>
                                {project.Description}
                            </span>
                            {project?.websiteLink && <a href={project?.websiteLink} target='_blank' className='w-fit bg-[#FF5D22] px-8 py-4 text-white font-700 text-[20px] flex items-center gap-2 rounded-[50px] cursor-pointer'>Go To Website</a>}

                        </div>

                    </div>
                )
            })}
        </>
    )
}

export default ProjectDescription

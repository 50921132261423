import React, { useEffect, useState } from 'react'
import commonConstants from '../../../utils/constants'
import UnderLineText from '../../atoms/UnderlineText'
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import ConsultNowModal from '../ConsultNowModal/ConsultNowModal';

const LanguageServices = ({ title, language, filterKey }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [showConsultApplyModal, setShowConsultApplyModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [modalWidth, setModalWidth] = useState('100%');

    const onChangeSelectedItem = (index) => {
        if (index === selectedIndex) {
            setShowConsultApplyModal(true)
        } else {
            setSelectedIndex(index)
        }
    }
    const onCloseModal = () => {
        setShowConsultApplyModal(false);
    }

    useEffect(() => {
        const updateWidth = () => {
            if (window.innerWidth >= 768) {
                setModalWidth('50%');
            } else {
                setModalWidth('100%');
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);


    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={'Software we are using'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                Our {title} Services
            </span>
            <div className='inline-grid gap-6 grid-cols-1 md:grid-cols-2 relative'>
                {/* {language === 'frontend' && <img className='absolute z-[1] w-[10%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' src='/svg/frontend-services-icon.svg' alt='icon'/>} */}
                {commonConstants.languageServices.filter(service => service[filterKey] === language).map((service, idx) => {
                    return (
                        <div key={idx} data-aos={service?.animationFading} className={`bg-white md:[background:_linear-gradient(${service?.backgroundGradient},_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-4 md:p-9 rounded-[30px] items-center flex flex-col gap-2`} onClick={() => { pathname !== '/services/digital-solutions' ? navigate('/services/language-page', { state: service }) : onChangeSelectedItem(idx) }}>
                            <div className='flex items-center gap-0'>
                                <img className='w-[93px] h-[71px]' src={service.serviceImg1} alt='' />
                                {service?.serviceImg2 && <img className='w-[93px] h-[71px]' src={service?.serviceImg2} alt='' />}
                            </div>
                            <span className='mt-3 text-[25px] leading-[32px] font-[500] text-center font-sans'>
                                {service.title}
                            </span>
                            <span className='text-center text-[17px] md:text-[21px] leading-[29px] md:leading-[32px] font-[400] font-sans'>
                                {service.description}
                            </span>
                            <div className='flex items-center cursor-pointer gap-4 mt-[20px] md:mt-[80px]'>
                                {pathname !== '/services/digital-solutions' ? <span className='font-[400] text-[18px] leading-[23px] text-theme'>
                                    Learn More
                                </span> : <span className='font-[400] text-[18px] leading-[23px] text-theme'>
                                    Consult Now
                                </span>}
                                <img src='/svg/theme-arrow-top-right.svg' alt='top-arrow' />
                            </div>
                        </div>
                    )
                })}
            </div>

            <Modal
                width={modalWidth}
                // destroyOnClose={true}
                title={
                    <div className='flex items-center justify-between mb-[2px] p-[10px] border-b-[1px] border-b-[#D3D3D3]'>
                        <div className='text-[37px] font-[500]'>Consult Now</div>
                        <img src='/svg/cancel-theme.svg' className='w-[24px] h-[24px] cursor-pointer' onClick={onCloseModal} /></div>
                } style={{ marginTop: '-50px' }} styles={{
                    content: { borderRadius: '40px' },
                    header: { textAlign: 'center' }
                }}
                closeIcon={false}
                open={showConsultApplyModal}
                footer={null}
                onCancel={onCloseModal}
            >
                <div className='max-h-[80vh] overflow-auto'>
                    <ConsultNowModal onCloseModal={onCloseModal} />
                </div>
            </Modal>
        </div>
    )
}

export default LanguageServices

import React, { useState } from 'react';
import styles from './style.module.scss';
import ButtonComponent from '../../atoms/Button';
import { TimePicker, message } from 'antd';
import commonConstants from '../../../utils/constants';
import moment from 'moment';

const ConsultNowModal = ({ role }) => {
    const [formData, setFormData] = useState({
        FirstName: '',
        Time: moment(),
        Email: '',
        PhoneNumber: '',
        Message: '',
    });

    const [loading, setLoading] = useState(false);

    const onChangeForm = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onTimeChange = (time) => {
        setFormData({
            ...formData,
            Time: time
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const sheetFormData = new FormData();

            sheetFormData.append("FirstName", formData.FirstName);
            sheetFormData.append("Time", formData.Time ? formData.Time.format('hh:mm A') : '');
            sheetFormData.append("Email", formData.Email);
            sheetFormData.append("PhoneNumber", formData.PhoneNumber);
            sheetFormData.append("Message", formData.Message);
            sheetFormData.append("Role", formData?.Role);

            console.log('Submitting form data:', {
                FirstName: formData.FirstName,
                Email: formData.Email,
                PhoneNumber: formData.PhoneNumber,
                Message: formData.Message,
                Time: formData.Time ? formData.Time.format('hh:mm A') : ''
            });

            const res = await fetch(commonConstants.google_sheet_deployed_url, {
                method: "POST",
                body: sheetFormData
            });

            setLoading(false);
            setFormData({
                FirstName: '',
                Time: moment(),
                Email: '',
                PhoneNumber: '',
                Message: '',
            });

            if (res.status === 200) {
                message.success("Thank you for showing interest in The Coding Studio, We will contact you soon.");
            } else {
                message.error("Submission failed. Please try again later.");
            }

        } catch (error) {
            setLoading(false);
            console.error('Fetch error:', error);
            message.error("An error occurred. Please try again later.");
        }
    };

    const isButtonDisabled = !formData.FirstName || !formData.Email || !formData.PhoneNumber;

    return (
        <div>
            <div className='flex gap-10 flex-col justify-center items-center'>
                <div data-aos="fade-left" className='flex flex-col md:flex-row gap-6 w-[100%]'>
                    <label className='flex flex-col gap-2 w-[48%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'>Name *</span>
                        <input onChange={onChangeForm} value={formData.FirstName} className={`${styles.greyInput}`} type="text" name="FirstName" placeholder='Name' />
                    </label>
                    <label className='flex flex-col gap-2 w-[48%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'>Start Time</span>
                        <TimePicker
                            onChange={onTimeChange}
                            value={formData.Time}
                            format={'hh:mm A'}
                            className={`${styles.timepicker}`}
                            minuteStep={15}
                            allowClear={false}
                        />
                    </label>
                </div>
                <div data-aos="fade-left" className='flex flex-col md:flex-row gap-6 w-[100%]'>
                    <label className='flex flex-col gap-2 w-[100%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'>Email ID *</span>
                        <input onChange={onChangeForm} value={formData.Email} className={`${styles.greyInput}`} type="email" name="Email" placeholder='Email Id' />
                    </label>
                    <label className='flex flex-col gap-2 w-[100%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'>Phone Number *</span>
                        <input onChange={onChangeForm} value={formData.PhoneNumber} className={`${styles.greyInput}`} type="number" name="PhoneNumber" placeholder='Phone Number' />
                    </label>
                </div>
                <div data-aos="fade-left" className='w-[100%] flex flex-col gap-2'>
                    <div className="text-[17px] leading-[21px] font-[400] font-sans">Message</div>
                    <textarea
                        id={''}
                        onChange={onChangeForm}
                        value={formData.Message}
                        name="Message"
                        rows={5}
                        placeholder='Type your message here...'
                        className={`w-[100%] ${styles.greyTextArea}`}
                    />
                </div>
                <div data-aos="fade-left" className='mt-[40px]'>
                    <ButtonComponent disabled={isButtonDisabled} loading={loading} iconClassName="absolute right-[15px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] ml-[-10px] leading-[24px] font-[500] font-sans" inputStyles={{ minWidth: '220px', width: '220px', height: '60px' }} label={"Submit"} onClick={onSubmit} />
                </div>
            </div>
        </div>
    );
};

export default ConsultNowModal;
import React, { useState } from 'react'
import UnderLineText from '../../atoms/UnderlineText'
import ImageWithBG from '../../atoms/ImageWithBG'

const ProfileAboutUs = ({ profile }) => {
    const [showExpertise, setShowExpertise] = useState(true);

    return (
        <div className='text-black pb-6 relative '>
            <div className='flex flex-col items-center justify-center bg-about-us-square bg-no-repeat bg-cover pb-[15px] pt-[30px] lg:pt-[120px] w-[100%] relative z-[1] justify-center '>
                <div className='flex flex-col px-2 md:px-[150px]'>
                    <div className=' flex flex-col lg:flex-row gap-[20px] items-center lg:gap-[150px]' >
                        <ImageWithBG animation={"fade-right"} className={"w-[40%]"} src={profile.profileImg} />
                        <div className='flex flex-col gap-6 md:gap-10'>
                            <div className='flex gap-4'>
                                <div className='flex flex-col gap-1'>
                                    <div data-aos="fade-right" className='font-[400] text-[25px] leading-[31px]'>Meet</div>
                                    <UnderLineText text={`${profile.first_name} ${profile.last_name}`}  textClassname="text-[24px] md:text-[45px] md:leading-[57px] font-[700] uppercase" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
                                    <div data-aos="fade-right" className='font-[700] text-[18px] md:text-[30px] md:leading-[38px]'>{profile.position}</div>
                                </div>
                                <div data-aos="fade-right" className='w-[45%] md:w-[112px]'><img src='/svg/left-hand-wave.svg' className='animate-swing' alt=''/></div>
                            </div>
                            <div data-aos="fade-right" className='font-[400] text-[18px] md:text-[20px] leading-[30px] md:leading-[32px]'>
                                {profile.description}
                            </div>
                            <div data-aos="fade-right" className='flex flex-col gap-3 mb-[34px]'>
                                <div className='flex flex-col gap-6'>
                                    <div className='[border:_1px_solid_#FF5D22] w-[40%]'></div>
                                    <div className='font-[400] text-[22px] leading-[27px] flex items-center gap-2'>Expertise in
                                        <img src='/svg/down-arrow.svg' onClick={() => { setShowExpertise(!showExpertise) }} alt=''/>
                                    </div>
                                </div>
                                {showExpertise && <div className='flex gap-4 flex-wrap'>
                                    {profile.expertise.map((exp) => {
                                        return <div className='w-[60px] h-[60px] shadow-[0px_4px_34px_0px_#00000026] [background:white] rounded-[50%] flex items-center justify-center'>
                                            <img src={exp.techImg} alt=''/>
                                        </div>
                                    })}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <p data-aos="zoom-in" className='hidden lg:block text-[7vw] font-[700] tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000_-27%,_#fff_79%)] uppercase font-sans mt-[-62px]'>
                    {profile.name}
                </p>
            </div>
        </div>
    )
}

export default ProfileAboutUs

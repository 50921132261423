import React from 'react'
import AddressMap from '../components/molecules/AddressMap/AddressMap'
import SubmitForm from '../components/molecules/SubmitForm/SubmitForm'
import ContactUsInfo from '../components/molecules/ContactUsInfo/ContactUsInfo'

const GetInTouch = () => {
    return (
        <div className='text-black relative '>
            <div className='flex flex-col items-center px-2 lg:px-[150px] bg-about-us-square bg-no-repeat bg-cover pb-[15px] pt-[60px] md:pt-[120px] w-[100%] relative z-[1] justify-center '>
                <div className='flex flex-col items-center justify-center'>
                    {/* <img data-aos="zoom-in" className=' w-[100%] pt-[10%]' src='/svg/Get in Touch-text.svg' /> */}
                    <p data-aos="zoom-in" className='text-center text-[50px] md:text-[98px] leading-[60px] md:leading-[120px] font-[700] font-sans'>Need help locating Us?</p>
                    <p data-aos="zoom-in" className='text-center text-[50px] md:text-[98px] md:leading-[120px] font-[700] font-sans text-[#FF5D22] italic '>Get in Touch</p>
                </div>
                <p data-aos="zoom-in" className='text-[11vw] font-[700] tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000_-27%,_#fff_79%)] uppercase font-sans mt-[0px] md:mt-[-40px]'>
                    Let' chat
                </p>
            </div>
            <div className='flex flex-col lg:flex-row gap-[55px] [background:_linear-gradient(180deg,_rgba(255,_93,_34,_0.2)_20%,_rgba(255,_93,_34,_0)_103.4%)] px-2 md:px-[120px] py-[50px] mt-[60px] md:mt-[120px]'>
                <ContactUsInfo title={'Contact us'}/>
                <SubmitForm />
            </div>
            <AddressMap />
        </div>
    )
}

export default GetInTouch

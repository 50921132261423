import React from 'react'

const ExperienceDesc = ({ title }) => {
    return (
        <div className='flex flex-col-reverse md:flex-row items-center mt-[100px] gap-[8%]'>
            <div className='flex flex-col gap-3 w-auto md:w-[55%] mt-[12%] md:mt-auto'>
                <span data-aos="fade-right" className='w-auto md:w-[80%] text-[34px] leading-[45px] md:text-[37px] md:leading-[47px] font-[700]'>
                    Elevate your digital experience with our {title} development services
                </span>
                <span data-aos="fade-right" className='w-auto md:w-[80%] text-[18px] leading-[32px] md:text-[20px] md:leading-[35px] font-[400]'>
                    We craft engaging user experiences through innovative design, prioritizing performance optimization, seamless integration, and rigorous quality assurance. Our collaborative approach ensures a solution tailored to your needs, driving tangible business outcomes and leaving a lasting impression on your audience. Experience the difference with us today.
                </span>
            </div>
            <div data-aos="fade-left" className='relative w-auto md:w-[45%] h-max'>
                {/* <div className={`absolute z-[1] h-[70px] flex items-center justify-center w-[70px] rounded-[50%] border-transparent backdrop-blur-[4px] top-[50%] left-[50%] contrast-[0.6]`}>
                    <img className="" src='/svg/play.svg' alt='wave' />
                </div> */}
                <img data-aos="fade-left" className='w-[100%]' src='/svg/backend-tech-team.svg' alt='' />
                <div className='text-[18px] md:text-[24px] min-w-[220px] flex items-center justify-around gap-3 leading-[26px] font-[500] absolute right-[10%] bottom-[-39px] md:bottom-[-60px] bg-white/60 [backdrop-filter:_blur(6px)] rounded-[20px] py-4 md:py-7 px-4 w-[60%]'>
                    <img src='/svg/achievement.svg' alt='achievement' />
                    Highly Qualified
                    Technical Team
                </div>
            </div>
        </div>
    )
}

export default ExperienceDesc

import React from 'react'

const EmptyPage = ({ text }) => {
    return (
        <div className='text-[35px] leading-[32px] font-[700] h-[100px] flex items-center justify-center my-[10vh]'>
            {text}
        </div>
    )
}

export default EmptyPage

import React from 'react'
import ProfilePortfolio from '../components/organisms/ProfilePortfolio/ProfilePortfolio'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'

const CaseStudies = () => {
    return (

        <div className='text-black pb-6 relative '>
            <div className='flex flex-col px-6 lg:px-[160px] items-center bg-about-us-square bg-no-repeat bg-cover pb-[15px] pt-[30px] lg:pt-[120px] w-[100%] relative z-[1] justify-center '>
                <div className='flex relative'>
                    <img data-aos="zoom-in" className=' w-[100%] pt-[10%]' src='/svg/case-studies-text.svg' alt=''/>
                    <img data-aos="fade-right" src='/svg/left-hand-wave.svg' className='absolute right-[35px] bottom-[8px] animate-swing w-[15%] md:w-auto ' alt=''/>
                </div>
                    <img data-aos="fade-right" src='/svg/standing-girl.svg' alt='' className='w-[28%] lg:w-auto absolute top-[3.5%] right-[0%] lg:right-[15%]' />
                <p data-aos="zoom-in" className='text-[9vw] font-[700] tracking-[8px] md:tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000_-27%,_#fff_79%)] uppercase font-sans mt-[15px] md:mt-[52px]'>
                    Case studies
                </p>
            </div>
            <div className='px-2 lg:px-[100px]'>
                <ProfilePortfolio title={'Work Done By TheCodingStudio'}/></div>
            <div className='mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>

    )
}

export default CaseStudies

import { Collapse, ConfigProvider } from 'antd'
import React from 'react'
import UnderLineText from '../../atoms/UnderlineText'

const FAQs = ({ faqItems }) => {
    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative faqs'>
            <UnderLineText text={'FAQs'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                Frequently Asked Questions
            </span>
            <ConfigProvider
                theme={{
                    components: {
                        Collapse: {
                            headerPadding: '40px 16px 30px 20px'
                        },
                    },
                }}
            >
                <Collapse className='faq-collapse-style' data-aos="fade-up" ghost bordered={false} expandIconPosition="end" items={faqItems} />
            </ConfigProvider>
        </div>
    )
}

export default FAQs
import React from 'react'
import UnderLineText from '../../atoms/UnderlineText'
import CompanyStats from '../../molecules/CompanyStats'

const Advantages = () => {
    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative px-2 lg:px-[100px]'>
            <UnderLineText isGreenLine={true} text={'Our Advantages'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-right" className='sub-title'>
                Why Us?
            </span>
            <div className='inline-grid gap-6 grid-cols-1 md:grid-cols-2 relative'>
                <div className='hidden md:block absolute z-[1] w-[10%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-[50%] shadow-[0px_24px_84px_0px_#0000004D]'>
                    <img className='w-[100%] h-[100%]' src='/svg/service-rocket.svg' alt='' />
                </div>
                <div data-aos="fade-right" className='[background:_#FF5D2233_40%] md:[background:_linear-gradient(to_left,#FF5D2233_40%,rgb(255,255,255,0)_100%)] p-8 rounded-[30px] items-center flex flex-col gap-2'>
                    <img data-aos='zoom-in-down' className='w-[93px] h-[71px]' src='/svg/advantages-1.svg' alt='' />
                    <span className='mt-3 text-[23px] md:text-[25px] leading-[32px] font-[500] text-center'>
                        We set goals, Not just tasks..
                    </span>
                    <span className='text-center text-[18px] leading-[28px] md:text-[21px] md:leading-[32px] font-[400] mt-2'>
                        We believe in setting ambitious goals rather than merely completing tasks, ensuring that our work drives meaningful outcomes and propels your business forward.
                    </span>
                </div>
                <div data-aos="fade-left" className='[background:_#FF5D2233_40%] md:[background:_linear-gradient(to_right,#FF5D2233_40%,rgb(255,255,255,0)_100%)] p-5 rounded-[30px] items-center flex flex-col gap-2'>
                    <img data-aos='zoom-in-down' className='w-[93px] h-[71px]' src='/svg/advantages-2.svg' alt='' />
                    <span className='mt-3 text-[23px] md:text-[25px] leading-[32px] font-[500] text-center'>
                        Highly qualified technical team
                    </span>
                    <span className='text-center text-[18px] leading-[28px] md:text-[21px] md:leading-[32px] font-[400] mt-2'>
                        Our team comprises seasoned experts with extensive experience and deep expertise in their respective fields, ensuring top-notch solutions tailored to your specific needs.
                    </span>
                </div>
                <div data-aos="fade-right" className='[background:_#FF5D2233_40%] md:[background:_linear-gradient(to_left,#FF5D2233_40%,rgb(255,255,255,0)_100%)] p-5 rounded-[30px] items-center flex flex-col gap-2'>
                    <img data-aos='zoom-in-down' className='w-[93px] h-[71px]' src='/svg/advantages-3.svg' alt='' />
                    <span className='mt-3 text-[23px] md:text-[25px] leading-[32px] font-[500] text-center'>
                        User-friendly design solution
                    </span>
                    <span className='text-center text-[18px] leading-[28px] md:text-[21px] md:leading-[32px] font-[400] mt-2'>
                        We specialize in crafting intuitive and engaging designs that prioritize user experience, ensuring seamless interactions and maximum satisfaction for your audience.
                    </span>
                </div>
                <div data-aos="fade-left" className='[background:_#FF5D2233_40%] md:[background:_linear-gradient(to_right,#FF5D2233_40%,rgb(255,255,255,0)_100%)] p-5 rounded-[30px] items-center flex flex-col gap-2'>
                    <img data-aos='zoom-in-down' className='w-[93px] h-[71px]' src='/svg/advantages-4.svg' alt='' />
                    <span className='mt-3 text-[23px] md:text-[25px] leading-[32px] font-[500] text-center'>
                        We are trendsetters!
                    </span>
                    <span className='text-center text-[18px] leading-[28px] md:text-[21px] md:leading-[32px] font-[400] mt-2'>
                        With innovative ideas and forward-thinking approaches, we lead the way in setting new trends and shaping the future of technology solutions.
                    </span>
                </div>
            </div>
            <CompanyStats bgGradient="[background:linear-gradient(to_bottom,white,#ECEEF7)]" />
        </div>
    )
}

export default Advantages
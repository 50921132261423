import React, { useEffect, useState } from 'react';
import { PureIncrement } from 'pure_counter';
import { motion, } from 'framer-motion';

const CompanyStats = ({ bgGradient }) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setKey(prevKey => prevKey + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='relative'>
            <motion.div data-aos="fade-up"
                className='rounded-[40px] themed-border animate-spin-slow'
                id='count-increment'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}>
                <div className={`${bgGradient ?? 'relative [background:linear-gradient(to_bottom,white,#F9ECED)]'} w-full rounded-[40px] grid grid-cols-1 md:grid-cols-2 md:grid-cols-4 justify-evenly gap-6 md:gap-3 items-center flex-wrap p-[40px]`}>
                    <div className='flex items-center justify-evenly'>
                        <div className='flex flex-col gap-3 items-center w-[227px] md:w-auto'>
                            <motion.div initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.5 }}>
                                <PureIncrement key={key} start={1} end={9} duration={2} className='text-theme text-[45px] leading-[57px] font-[700]' />
                                <span className='text-theme text-[45px] leading-[57px] font-[700]'>+</span>
                            </motion.div>
                            <span className='text-[19px] leading-[24px] font-[500]'>Years of Experience</span>
                        </div>
                        <img src='/svg/gray-dot.svg' className='animate-pulse hidden md:block' alt='dot' />
                    </div>
                    <div className='flex items-center justify-evenly'>
                        <div className='flex flex-col gap-3 items-center w-[227px] md:w-auto'>
                            <motion.div initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.5 }}>
                                <PureIncrement key={key} start={1} end={60} duration={2} className='text-theme text-[45px] leading-[57px] font-[700]' />
                                <span className='text-theme text-[45px] leading-[57px] font-[700]'>+</span>
                            </motion.div>
                            <span className='text-[19px] leading-[24px] font-[500]'>Successful Projects</span>
                        </div>
                        <img src='/svg/gray-dot.svg' className='animate-pulse hidden md:block' alt='dot' />
                    </div>
                    <div className='flex items-center justify-evenly'>
                        <div className='flex flex-col gap-3 items-center w-[227px] md:w-auto'>
                            <div>
                                <PureIncrement key={key} start={1} end={35} duration={2} className='text-theme text-[45px] leading-[57px] font-[700]' />
                                <span className='text-theme text-[45px] leading-[57px] font-[700]'>+</span>
                            </div>
                            <span className='text-[19px] leading-[24px] font-[500]'>Talented IT Professionals</span>
                        </div>
                        <img src='/svg/gray-dot.svg' className='animate-pulse hidden md:block' alt='dot' />
                    </div>
                    <div className='flex items-center justify-evenly'>
                        <div className='flex flex-col gap-3 items-center w-[227px] md:w-auto '>
                            <div>
                                <PureIncrement key={key} start={1} end={45} duration={2} className='text-theme text-[45px] leading-[57px] font-[700]' />
                                <span className='text-theme text-[45px] leading-[57px] font-[700]'>+</span>
                            </div>
                            <span className='text-[19px] leading-[24px] font-[500]'>Happy Clients</span>
                        </div>
                        <div className='w-[18px] h-[18px] xs:hidden' ></div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default CompanyStats
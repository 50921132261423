import React from 'react'
import UnderLineText from '../../atoms/UnderlineText';
import './style.scss'
import { useNavigate } from 'react-router-dom';

const OurServices = ({ serviceScreen, whyChooseSection }) => {
  const navigate = useNavigate()

  return (
    <div className={`flex flex-col gap-[10px] md:gap-[30px] mt-[30px] md:mt-[100px] relative`}>
      <UnderLineText text={'Our Services'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
      <span data-aos="fade-up" className='sub-title'>{serviceScreen ? "We can help you with" : "Let's Check our Services"}</span>
      <div className='inline-grid gap-6 grid-cols-1 md:grid-cols-2  relative'>
        <div className='hidden md:block absolute z-[1] w-[10%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-[50%] shadow-[0px_24px_84px_0px_#0000004D]'>
          <img className='w-[100%] h-[100%] animate-wiggle' src='/svg/service-rocket.svg' alt='rocket' />
        </div>
        <div data-aos="fade-right" className='bg-white md:[background:_linear-gradient(to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-9 rounded-[30px] items-center flex flex-col gap-2 cursor-pointer' onClick={() => { navigate('/services/frontend') }}>
          <img className='w-[93px] h-[71px]' src='/svg/frontend.svg' alt='frontend' />
          <span className='mt-3 md:text-[30px] text-[25px] leading-[38px] text-center font-sans service-title'>
            Frontend Development
          </span>
          <span className='text-center text-[18px] md:text-[23px] leading-[30px] md:leading-[35px] font-[400] font-sans'>
            Transform your ideas into stunning, interactive, and responsive websites with our expert front-end development services. We use the latest technologies to create user-friendly interfaces that engage and captivate your audience. Let us bring your vision to life with seamless design and functionality.
          </span>
        </div>
        <div data-aos="fade-left" className='bg-white md:[background:_linear-gradient(to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-9 rounded-[30px] items-center flex flex-col gap-2 cursor-pointer' onClick={() => { navigate('/services/backend') }}>
          <img className='w-[93px] h-[71px]' src='/svg/backend.svg' alt='backend' />
          <span className='mt-3 md:text-[30px] text-[25px] leading-[38px] font-[400] text-center font-sans service-title'>
            Backend Development
          </span>
          <span className='text-center text-[18px] md:text-[23px] leading-[30px] md:leading-[35px] font-[400] font-sans'>
            Power your website with robust, scalable, and secure back-end solutions. Our expert team ensures seamless performance and reliable data management for your applications.
          </span>
        </div>
        <div data-aos="fade-right" className='bg-white md:[background:_linear-gradient(to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-9 rounded-[30px] items-center flex flex-col gap-2 cursor-pointer' onClick={() => { navigate('/services/fullstack') }}>
          <img className='w-[93px] h-[71px]' src='/svg/fullstack.svg' alt='fullstack' />
          <span className='mt-3 md:text-[30px] text-[25px] leading-[38px] font-[400] text-center font-sans service-title'>
            Fullstack Development
          </span>
          <span className='text-center text-[18px] md:text-[23px] leading-[30px] md:leading-[35px] font-[400] font-sans '>
            Get comprehensive development solutions with our full-stack services. We handle both front-end and back-end development to deliver seamless, high-performance, and scalable applications tailored to your needs.
          </span>
        </div>
        <div data-aos="fade-left" className='bg-white md:[background:_linear-gradient(to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-9 rounded-[30px] items-center flex flex-col gap-2 cursor-pointer' onClick={() => { navigate('/services/digital-solutions') }}>
          <img className='w-[93px] h-[71px]' src='/svg/backend.svg' alt='backend' />
          <span className='mt-3 md:text-[30px] text-[25px] leading-[38px] font-[400] text-center font-sans service-title'>
          Consultation for Digital Solutions
          </span>
          <span className='text-center text-[18px] md:text-[23px] leading-[30px] md:leading-[35px] font-[400] font-sans'>
          Digital consultation refers to professional guidance and advice provided by experts in various digital solutions areas to help businesses optimize their online presence, develop effective strategies, and achieve their marketing goals.
          </span>
        </div>
      </div>

      {/* {whyChooseSection && <SatisfactionServices />} */}

    </div>
  )
}

export default OurServices
import React from 'react'

const LanguageDescription = ({ service }) => {
    return (
        <div className='flex flex-col lg:flex-row items-center my-[100px] gap-[8%]'>
            <div data-aos="fade-right" className='relative w-auto lg:w-[45%] h-max'>
                <div className={`absolute z-[1] h-[70px] flex items-center justify-center w-[70px] rounded-[50%] border-transparent backdrop-blur-[4px] top-[50%] left-[50%] contrast-[0.6]`}>
                    <img className="" src='/svg/play.svg' alt=''/>
                </div>
                <img data-aos="fade-right" className='w-[100%]' src='/svg/choose-our-service.svg' alt=''/>
                <div className='text-[18px] md:text-[24px] min-w-[220px] flex items-center justify-around gap-3 leading-[26px] font-[500] absolute right-[10%] bottom-[-60px] bg-white/60 [backdrop-filter:_blur(6px)] rounded-[20px] py-7 px-4 w-[60%]'>
                    <img src='/svg/achievement.svg' alt=''/>
                    100% Customer
                    Satisfaction
                </div>
            </div>
            <div className='flex flex-col gap-8  w-auto lg:w-[55%] mt-[79px] lg:mt-auto'>
                <span data-aos="fade-left" className='text-[23px] w-max leading-[35px] font-[400] px-[20px] py-[10px] bg-[#00000012] rounded-[80px]'>Why choose our services</span>

                <span data-aos="fade-right" className='w-[90%] text-[33px] leading-[41px] font-[700]'>
                    {service.title} development
                </span>
                <span data-aos="fade-right" className='w-[99%] text-[16px] md:text-[21px] leading-[27px] md:leading-[35px] font-[400]'>
                    With every new day, there are some or the other new
                    advancements happening in the world of technology. In that,
                    Frontend technology is also continuously evolving and ReactJS is
                    breaking records in the website development space. According
                    to a survey by Stack Overflow, ReactJS Frontend is the most
                    popular development preferred by businesses. ReactJS
                    developer assures quality applications with the correct interfaces.
                    This is a wonderful Javascript framework loved by the developers.
                    Most of the small, medium sized and enterprise companies are
                    leveraging ReactJS development. Our expert developers have
                    got you all covered to create a powerful and quality
                    application in a quick time.
                </span>
            </div>

        </div>
    )
}

export default LanguageDescription

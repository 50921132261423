import React from 'react';
import styles from './style.module.scss';
import Lottie from 'lottie-react';
import whiteLoader from '../../../lotties/white-loader.json';
import { motion } from 'framer-motion'

const ButtonComponent = (props) => {
  const {
    name,
    label,
    disabled,
    onClick,
    inputStyles,
    iconPath,
    iconClassName,
    className,
    loading,
    labelClassname,
    ...rest
  } = props;
  return (
    <motion.button
      name={name}
      disabled={disabled || loading}
      onClick={onClick}
      className={`${className ? className : ''} ${styles.button}`}
      style={inputStyles}
      whileTap={{ scale: 0.9 }}
      // whileHover={{ scale: 1.1 }}
      transition={{ bounceDamping: 10 }}
      {...rest}
    >
      {
        loading ?
          <Lottie
            className={`${styles.loaderStyle}`}
            animationData={whiteLoader}
            loop={true}
          />
          :
          <>
            <span className={labelClassname || ""}>
              {label}
            </span>
            {iconPath && <motion.img animate={{ x: [0, 10, 0] }} transition={{ repeat: Infinity, duration: 1 }} className={iconClassName} src={iconPath} />}
          </>
      }
    </motion.button>
  );
};

export default ButtonComponent;

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='pt-[3%] pl-[5%] pr-[2%]'>
      <div className='mb-[15px]'>Welcome to TheCodingStudio. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you visit our website <a href='https://thecodingstudio.in/' className='text-[--themeColor]'>
          thecodingstudio.in </a> or make a purchase from us.
        Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</div>
      <div className='mb-[15px]'><span className='font-bold text-7px]'> 1. Information We Collect </span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal' >
        <li><span className='font-semibold'>Personal Data:</span> We may collect personal information such as your name, email address, phone number, billing and shipping address, and payment information
          when you make a purchase or create an account with us.</li>
        <li><span className='font-semibold'>Usage Data:</span> We may collect information about how you use our website, including your IP address,
          browser type, pages visited, and the time and date of your visit.</li>
        <li><span className='font-semibold'>Cookies:</span> We use cookies to enhance your experience on our website,
          including remembering your preferences and tracking your browsing behavior.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 2. How We Use Your Information </span> </div>
      <div className='mb-[15px]'>We may use the information we collect for various purposes, including:</div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-disc'>
        <li> Processing your orders and managing your account.</li>
        <li> Sending you order confirmations, invoices, and other transactional communications.</li>
        <li>Providing customer support and responding to your inquiries.</li>
        <li> Improving our website and services based on your feedback and usage patterns.</li>
        <li> Sending you marketing communications, if you have opted in to receive them.</li>
        <li>Complying with legal obligations and protecting our rights.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 3. Payment Terms and Conditions </span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Payment Methods:</span> We accept [list payment methods, e.g., credit cards, debit cards, PayPal, etc.].</li>
        <li><span className='font-semibold'>Payment Processing:</span> Payments are processed through secure third-party payment processors.
          We do not store your payment information on our servers.</li>
        <li><span className='font-semibold'>Billing:</span> You agree to provide current, complete, and accurate purchase and account information for all purchases made via our website.
          You agree to promptly update your account and payment information, including your email address and payment method details,
          so we can complete your transactions and contact you as needed.</li>
        <li><span className='font-semibold'>Order Confirmation:</span> After placing an order, you will receive a confirmation email with the details of your purchase.</li>
        <li><span className='font-semibold'>Refund Policy:</span> Please refer to our [Refund Policy] for detailed information on refunds, returns, and exchanges.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 4. Sharing Your Information </span> </div>
      <div className='mb-[15px]'>We may share your personal information with:</div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Service Providers:</span> Third-party vendors that help us operate our website, process payments, and deliver products and services to you.</li>
        <li><span className='font-semibold'>Legal Requirements:</span> We may disclose your information if required by law or in response to valid legal processes,
          such as a subpoena or court order.</li>
        <li><span className='font-semibold'>Business Transfers:</span>  In the event of a merger, acquisition, or sale of all or a portion of our assets,
          your information may be transferred as part of that transaction.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 5. Data Security </span> </div>
      <div className='mb-[15px]'>We implement reasonable security measures to protect your personal information from unauthorized access,
        use, or disclosure. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.
      </div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 6. Your Rights </span> </div>
      <div className='mb-[15px]'>You have the right to:</div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-disc'>
        <li> Access and update your personal information.</li>
        <li> Request the deletion of your personal information.</li>
        <li>Opt out of receiving marketing communications from us.</li>
        <li> Withdraw consent for the processing of your personal information.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 7. Third-Party Links </span> </div>
      <div className='mb-[15px]'>Our website may contain links to third-party websites that are not operated by us.
        We are not responsible for the privacy practices of these websites.
        We encourage you to review the privacy policies of any third-party sites you visit.
      </div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 8. Changes to This Privacy Policy </span> </div>
      <div className='mb-[15px]'>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
        The updated policy will be posted on this page with the "Effective Date" updated accordingly.
        We encourage you to review this policy periodically.
      </div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 9. Contact Us </span> </div>
      <div className='mb-[15px]'>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at: </div>
      <ul className='mb-[55px] flex flex-col gap-[10px] list-disc'>
        <li><span className='font-semibold'>Email: </span>
          <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`mailto:hr@thecodingstudio.in`}>
            hr@thecodingstudio.in
          </a></li>
        <li><span className='font-semibold'>Phone: </span>
          <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:+917990345580`}>
            +917990345580
          </a></li>
        <li><span className='font-semibold'>Address: </span>  436-437,The Boulevard, Near Pratham Circle, Gallaria Street Green city road , pal , Adajan, Surat, Gujarat 395009</li>
      </ul>

    </div>
  )
}

export default PrivacyPolicy
import React, { useState } from 'react'
import TabsComponent from '../../atoms/Tabs'
import commonConstants from '../../../utils/constants';
import './GalleryTabs.scss'
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';

const GalleryTabs = () => {
    const [galleryTab, setGalleryTab] = useState('all');

    const onChangeTab = (e) => {
        setGalleryTab(e)
    }
    return (
        <div className='mt-[65px] lg:mt-[200px]'>
            <TabsComponent
                data-aos="fade-up"
                activeKey={galleryTab}
                animated={false}
                destroyInactiveTabPane={true}
                centered={true}
                onChange={onChangeTab}
                items={commonConstants.galleryTabs}
                className="gallery-tabs"
            />

            {galleryTab === 'all' ? (
                commonConstants.photosAll.length > 0 ? (
                    <div className='mt-[65px]'>
                        <img data-aos="zoom-in" className='w-[100%]' src='/images/office-images/navratri.jpg' alt='' />
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-[60px] w-[100%] mt-[50px]'>
                            {commonConstants.photosAll.map((photo, index) => (
                                <img
                                    key={index}
                                    data-aos={index % 2 === 0 ? "fade-down-right" : "fade-up-right"}
                                    className='w-[100%] h-[350px] md:h-[559px]'
                                    src={photo.image}
                                    alt=''
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <EmptyPage text={'No Photos'} />
                )
            ) : galleryTab === 'office' ? (
                commonConstants.photosOffice.length > 0 ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-[60px] w-[100%] mt-[50px]'>
                        {commonConstants.photosOffice.map((photo, index) => (
                            <img
                                key={index}
                                data-aos={index % 2 === 0 ? "fade-down-right" : "fade-up-right"}
                                className='w-[100%] h-[350px] md:h-[559px]'
                                src={photo.image}
                                alt=''
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyPage text={'No Photos'} />
                )
            ) : galleryTab === 'fun' ? (
                commonConstants.photosFun.length > 0 ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-[60px] w-[100%] mt-[50px]'>
                        {commonConstants.photosFun.map((photo, index) => (
                            <img
                                key={index}
                                data-aos={index % 2 === 0 ? "fade-down-right" : "fade-up-right"}
                                className='w-[100%] h-[350px] md:h-[559px]'
                                src={photo.image}
                                alt=''
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyPage text={'No Photos'} />
                )
            ) : galleryTab === 'infrastructure' ? (
                commonConstants.photosInfrastructure.length > 0 ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-[60px] w-[100%] mt-[50px]'>
                        {commonConstants.photosInfrastructure.map((photo, index) => (
                            <img
                                key={index}
                                data-aos={index % 2 === 0 ? "fade-down-right" : "fade-up-right"}
                                className='w-[100%] h-[350px] md:h-[559px]'
                                src={photo.image}
                                alt=''
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyPage text={'No Photos'} />
                )
            ) : (
                <EmptyPage text={'No Photos'} />
            )}

        </div>

    )
}

export default GalleryTabs

import React from 'react'
import UnderLineText from '../../../atoms/UnderlineText';
import './ProjectProcess.scss'

const ProjectProcess = ({ service }) => {
    return (
        <div className='hidden sm:flex flex-col gap-[30px] mt-[100px] relative pb-[140px] '>
            <UnderLineText text={'Process'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title font-sans'>
                Our Work Process
            </span>
            <div className='flex flex-col justify-center items-center relative'>
                {service?.projectWorkProcess?.map((work) => {
                    const { title, description } = work;
                    const { text, contributors } = description;
                    let formattedText = text;
                    contributors?.forEach(contributor => {
                        const regex = new RegExp(contributor.name, 'g');
                        formattedText = formattedText.replace(regex,
                            `<span className='text-[${contributor?.highlight}] font-[${contributor?.fontWeight}]'>${contributor.name}</span>`
                        );
                    });
                    let displayContent = work?.placement === "left" ? "left-[95px]" : "right-[133px]"
                    return <>
                        <div className='relative'>
                            <div className='absolute top-[80px] p-4' data-aos={`fade-${work.placement}`}>
                                <div className={`${displayContent} bubble ${work?.placement} ${work.background}`}>
                                    <div className='font-sans'>
                                        <div className='text-[25px] leading-[41px] font-[500]'>{title}</div>
                                        <div className='text-[18px] leading-[32px] font-[400]'>
                                            <span dangerouslySetInnerHTML={{ __html: formattedText }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img data-aos="fade-up" src={work?.imageProject1} alt=''/>
                        {work?.imageProject2 && <img data-aos="fade-up" src={work.imageProject2} alt=''/>}
                    </>
                })}
            </div>

        </div>
    )
}

export default ProjectProcess

import React from 'react'
import UnderLineText from '../../atoms/UnderlineText';

const HubspotWorkProcess = ({ service }) => {
    const steps = [
        { title: `${service?.title} Setup and Onboarding`, icon: '/svg/hubspot-process-setup.svg', bgColor: 'bg-[#FF650E33]', borderColor: 'outline-[#FF650E]', arrowType: 'down' },
        { title: 'Strategy and Planning', icon: '/svg/hubspot-process-strategy.svg', bgColor: 'bg-[#00AC8D33]', borderColor: 'outline-[#00AC8D]', arrowType: 'up' },
        { title: 'Lead Generation', icon: '/svg/hubspot-process-lead.svg', bgColor: 'bg-[#5A49F833]', borderColor: 'outline-[#5A49F8]', arrowType: 'down' },
        { title: 'Sales and CRM Integration', icon: '/svg/hubspot-process-sales.svg', bgColor: 'bg-[#36BAFE33]', borderColor: 'outline-[#36BAFE]', arrowType: 'up' },
        { title: 'Testing and Optimization', icon: '/svg/hubspot-process-testing.svg', bgColor: 'bg-[#FF383833]', borderColor: 'outline-[#FF3838]', arrowType: 'down' },
        { title: 'Training and Education', icon: '/svg/hubspot-process-training.svg', bgColor: 'bg-[#F8AE1F33]', borderColor: 'outline-[#F8AE1F]', arrowType: 'up' },
        { title: 'Review and Reporting', icon: '/svg/hubspot-process-review.svg', bgColor: 'bg-[#3DAF2B33]', borderColor: 'outline-[#3DAF2B]' },
    ];
    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={'Process'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                Our Work Process
            </span>
            <div className="flex justify-between items-center">
                {steps.map((step, index) => (
                    <div key={index} className={`flex w-[12%] flex-col items-center ${index % 2 === 0 ? '-mt-[6%]' : 'mt-[6%]'}`}>
                        {
                            step.arrowType === 'up' ?
                                <img className='ml-[30%] mb-[35%] w-[70%]' src='/svg/grey-dotted-arrow-up.svg' alt=''/>
                                :
                                <></>
                        }
                        <div className={`p-[20%] aspect-square flex items-center justify-center rounded-[50%] outline outline-offset-[10px] outline-[2px] ${step.borderColor} ${step.bgColor}`}>
                            <img src={step.icon} alt=''/>
                        </div>
                        <span className='text-center text-[1.3vw] font-[400] mt-6'>{step.title}</span>

                        {
                            step.arrowType === 'down' ?
                                <img className='ml-[50%] mt-[5%] w-[80%]' src='/svg/grey-dotted-arrow-down.svg' alt=''/>
                                :
                                <></>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HubspotWorkProcess
import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import BackendPage from './BackendPage';
import FrontendPage from './FrontendPage';
import FullstackPage from './FullstackPage';
import DigitalSolutions from './DigitalSolutions';

const ServiceSubPage = () => {
    const params = useParams();
    return (
        <div className='text-black px-2 md:px-[20px] lg:px-[100px] pb-6'>
            {
                params.tech === 'frontend' ?
                    <FrontendPage />
                    :
                    params.tech === 'backend' ?
                        <BackendPage />
                        :
                        params.tech === 'fullstack' ?
                            <FullstackPage />
                            :
                            params.tech === 'digital-solutions' ?
                                <DigitalSolutions />
                                :
                                <div className='text-[35px] text-center font-[600]'>
                                    No Page Found!
                                </div>
            }
        </div>
    )
}

export default ServiceSubPage
import React from 'react'
import ButtonComponent from '../../atoms/Button'
import { useNavigate } from 'react-router-dom';
import { useMotionValueEvent, useScroll, motion } from 'framer-motion';

const ContactUs = () => {
    const navigate = useNavigate();
    const { scrollYProgress } = useScroll();

    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    })

    return (
        <motion.div
            style={{
                scaleX: scrollYProgress,
                x: '0%',
                y: '0%'
            }}
            data-aos="fade-up"
            className='flex flex-col sm:flex-row items-center gap-[20px] sm:gap-[100px] mt-[50px] sm:mt-[150px] mb-[60px] sm:mb-[120px] mx-auto w-full sm:w-max py-[20px] sm:py-[30px] px-[20px] sm:px-[40px] rounded-[20px] sm:rounded-[30px] shadow-[0px_20px_30px_0px_#FF5D221A] sm:shadow-[0px_40px_60px_0px_#FF5D221A] [background:_linear-gradient(221.43deg,_#414A50_0%,_#232123_100%)]'
        >
            <span className='text-white text-[20px] sm:text-[28px] leading-[28px] sm:leading-[35px] font-[500] text-center sm:text-left'>
                Need help locating us?
            </span>
            <ButtonComponent
                iconClassName="absolute right-[10px]"
                iconPath="/svg/right-white-arrow.svg"
                labelClassname="text-[14px] sm:text-[16px] ml-[-15px] sm:ml-[-25px] leading-[24px] sm:leading-[28px] font-[400]"
                inputStyles={{ minWidth: '150px', width: '150px', height: '40px', boxShadow: 'none' }}
                label={"CONTACT US"}
                onClick={() => { navigate('/getInTouch') }}
            />
        </motion.div>
    )
}

export default ContactUs
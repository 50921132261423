import React from 'react';
import './Branding.scss'

const Branding = () => {
    return (
        <div>
            <div className='flex flex-col mx-auto md:mx-[-100px] mt-[150px] md:mt-[250px] mb-[80px] md:mb-[200px]'>
                <div className='skew-box'>
                    <div className='overflow-hidden whitespace-nowrap relative'>
                        <div className='flex items-center gap-[40px] animate-scroll-left font-bold text-[24px] md:text-[37px] text-white font-sans'>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Customized Website</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Frontend Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Backend Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>FullStack Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Digital Solutions</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>UI/UX Design</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Customized Website</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Frontend Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Backend Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>Digital Solutions</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>FullStack Development</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                            <div>UI/UX Design</div>
                            <img src='/svg/yellow-star.svg' alt='star' />
                        </div>
                    </div>
                </div>
                <div className='bg-[#FF5D22] h-[85px] md:h-[128px] flex items-center gap-[40px] z-[1] mt-[24px] md:mt-[75px]'>
                    <div className='overflow-hidden whitespace-nowrap relative'>
                        <div className='flex items-center gap-[40px] animate-scroll-right font-bold text-[24px] md:text-[37px] text-white font-sans'>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Branding</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Creative UI/UX Design</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Strategy</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Put Your Best Foot Forward</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Branding</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Creative UI/UX Design</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Strategy</div>
                            <img src='/svg/white-star.svg' alt='star' />
                            <div>Put Your Best Foot Forward</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Branding

import './App.css';
import './Globals.css';
import Footer from './components/organisms/Footer';
import Header from './components/organisms/Header';
import AppRoutes from './routes';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import HeaderMobile from './components/organisms/HeaderMobile/HeaderMobile';
import { useLocation } from 'react-router-dom';

const App = () => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    AOS.init({
      duration: window.innerWidth > 1024 ? 1000 : ""
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="app flex flex-col relative min-h-screen bg-theme-image bg-no-repeat bg-cover">
      {
        window.innerWidth > 768 ? <Header /> : <HeaderMobile />
      }
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'

const Shipping = () => {
    return (
        <div className='pt-[3%] pl-[5%] pr-[2%]'>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 1. Shipping Policy </span> </div>
            <div className='mb-[15px]'>
                At TheCodingStudio, we strive to provide prompt and reliable delivery services to ensure your orders reach you in a timely manner.
                This Shipping and Deliveries policy outlines the terms and conditions
                related to shipping, including available shipping options, delivery times, and shipping charges.
            </div>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 2. Shipping Options and Charges </span> </div>
            <ul className='mb-[15px] list-disc'>
                <li className='mb-[15px]'><span className='font-semibold'>Domestic Shipping:</span>
                    We offer several shipping options within India, including standard, expedited, and express delivery.</li>
                <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                    <li><span className='font-semibold'>Standard Shipping:</span> Typically takes [X-X] business days. Shipping charges are calculated based on the weight and destination of your order.</li>
                    <li><span className='font-semibold'>Expedited Shipping:</span> Typically takes [X-X] business days. Additional charges apply.</li>
                    <li><span className='font-semibold'>Express Shipping:</span>Typically takes [X-X] business days. Additional charges apply.</li>
                </ul>
            </ul>

            <ul className='mb-[15px] list-disc'><li><span className='font-semibold'>International Shipping:</span> We ship to select countries outside India.
                International shipping charges and delivery times vary depending on the destination and shipping method chosen.
                Please note that customs duties and taxes may apply and are the responsibility of the customer.</li></ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 3. Processing Time</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Order Processing:</span> Orders are processed within [X] business days after payment has been confirmed. Orders placed on weekends or holidays will be processed on the next business day.</li>
                <li><span className='font-semibold'>Custom Orders:</span>Custom or personalized orders may require additional processing time. We will notify you of any delays.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 4. Delivery Time</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Estimated Delivery Time: </span> Delivery times are estimated based on the shipping method selected at checkout.
                    Please note that delivery times are not guaranteed and may be affected by factors such as weather conditions, carrier delays, or customs processing.</li>
                <li><span className='font-semibold'>Tracking Your Order:</span> Once your order has been shipped, you will receive a confirmation email with a tracking number.
                    You can use this tracking number to monitor the status of your shipment on the carrier’s website.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 5. Shipping Restrictions</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>PO Boxes: </span> We do not ship to PO Box addresses. Please provide a physical address for delivery.
                    Please note that delivery times are not guaranteed and may be affected by factors such as weather conditions, carrier delays, or customs processing.</li>
                <li><span className='font-semibold'>Restricted Areas:</span> Some remote or restricted areas may not be eligible for certain shipping methods.
                    If we are unable to ship to your location, we will contact you to make alternative arrangements.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 6. Undeliverable Packages</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Incorrect Address: </span> Please ensure that you provide the correct shipping address at checkout.
                    We are not responsible for orders shipped to incorrect addresses provided by the customer.</li>
                <li><span className='font-semibold'>Failed Delivery Attempts:</span>  If a delivery attempt is unsuccessful due to an incorrect address or the recipient being unavailable, the package may be returned to us.
                    In such cases, additional shipping charges may apply to reship the order.</li>
                <li><span className='font-semibold'>Refused Packages:</span>  If a package is refused upon delivery,
                    it will be returned to us. Any additional shipping charges and return fees will be deducted from your refund.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 7. Lost or Damaged Shipments</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Lost Shipments: </span> If your package is lost in transit, please contact us at <a href='https://thecodingstudio.in/' className='text-[--themeColor]'>
                    thecodingstudio.in </a> within [X] days of the expected delivery date.
                    We will work with the carrier to locate your package and provide a replacement or refund if necessary.</li>
                <li><span className='font-semibold'>Damaged Shipments:</span> If your package arrives damaged, please contact us immediately at <a href='https://thecodingstudio.in/' className='text-[--themeColor]'>
                    thecodingstudio.in </a>. Provide photos of the damaged package and contents,
                    and we will assist you in filing a claim with the carrier and arranging for a replacement or refund.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 8. Contact Us </span> </div>
            <div className='mb-[15px]'>For any questions or concerns regarding our shipping and delivery policies, please contact us at: </div>
            <ul className='mb-[55px] flex flex-col gap-[10px] list-disc'>
                <li><span className='font-semibold'>Email: </span>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`mailto:hr@thecodingstudio.in`}>
                        hr@thecodingstudio.in
                    </a></li>
                <li><span className='font-semibold'>Phone: </span>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:+917990345580`}>
                        +917990345580
                    </a></li>
                <li><span className='font-semibold'>Address: </span>  436-437,The Boulevard, Near Pratham Circle, Gallaria Street Green city road , pal , Adajan, Surat, Gujarat 395009</li>
            </ul>

        </div>
    )
}

export default Shipping
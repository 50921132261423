import React, { forwardRef } from 'react';

const TabsComponent = forwardRef(function TabsComponent({ activeKey, onChange, items, className, style, ...rest }, ref) {

    const { Tabs } = require('antd');

    return (
        <Tabs
            items={items}
            activeKey={activeKey}
            onChange={onChange}
            className={`theme-tabs ${className}`}
            style={style}
            {...rest}
        />
    )
})

export default TabsComponent
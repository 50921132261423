import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import ProfilePage from './ProfilePage';

const ProfileSubPage = () => {
    const params = useParams();
    const { state } = useLocation();
    console.log('stateapp :>> ', state);
    return (
        <div className='text-black px-2 md:px-[20px] lg:px-[100px] pb-6'>
            {
                params.name === `${state?.first_name}` ?
                    <ProfilePage state={state} />
                    :
                    <div className='text-[35px] text-center font-[600]'>
                        No Page Found!
                    </div>
            }
        </div>
    )
}

export default ProfileSubPage
import React, { useState } from 'react'
import ButtonComponent from '../../atoms/Button'
import UnderLineText from '../../atoms/UnderlineText'
import CompanyStats from '../../molecules/CompanyStats';
import { motion } from 'framer-motion'

const AboutUs = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
            }
        }
    }

    // const text = 'With our team of talented developers, designers, and engineers, we deliver cutting-edge applications, web solutions, and enterprise software tailored to your unique needs.'
    return (
        <div className='flex flex-col gap-[30px] mt-[100px]' >
            <UnderLineText text={'About us'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title font-sans'>
                TheCodingStudio
            </span>
            <div data-aos="fade-up" className='xs:flex-col xs:items-end flex gap-[15px] md:gap-[20px] lg:gap-[60px] items-center justify-between md:items-end md:gap-4  md:justify-end'>
                <span className={`text-[20px] leading-[32px] font-[400] font-sans ${isExpanded ? '' : 'line-clamp-2'} overflow-hidden`}>
                    Welcome to <span className='font-[500]'>TheCodingStudio</span>, the catalyst for startup success.
                    With over a decade of expertise, we specialize in transforming visionary ideas into cutting-edge products,
                    optimizing operational processes, and accelerating growth. Our commitment to excellence and innovation is the
                    cornerstone of our services, designed to propel your startup to unprecedented heights.
                </span>

                <ButtonComponent iconClassName="absolute right-[10px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[16px] ml-[-25px] leading-[28px] font-[400] md:justify-end md:items-end md:flex" inputStyles={{ minWidth: '160px', width: '160px', height: '50px' }} label={isExpanded ? "Read less" : "Read more"} onClick={handleReadMore} />
            </div>
            <motion.div data-aos="fade-up" className='relative mb-[150px]'>
                <img data-aos='zoom-in' className='rounded-[30px] w-full' src='/images/about-us-img.jpg' alt='about-us' />
                <motion.div
                    initial='hidden'
                    animate='visible'
                    transition={{ staggerChildren: 0.15 }}
                    className='bg-black w-auto text-[14px] leading-[14px] md:text-[20px] md:leading-[28px] font-[400] absolute md:right-[80px] bottom-[-60px] bg-white/60 [backdrop-filter:_blur(6px)] rounded-[20px] p-4 md:w-[58%] lg:w-[40%]'
                >
                    {/* {text.split('').map((char) => { */}
                    {/* return ( */}
                    <motion.span variants={textVariants} >
                        With our team of talented developers, designers, and engineers, we deliver cutting-edge applications, web solutions, and enterprise software tailored to your unique needs.
                    </motion.span>
                    {/* ) */}
                    {/* })} */}
                </motion.div>
            </motion.div>
            <CompanyStats />
        </div>
    )
}

export default AboutUs
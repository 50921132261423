import React from 'react'
import UnderLineText from '../../../atoms/UnderlineText'

const ProjectServices = ({ service }) => {
    return (

        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={'Services'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                Services used in this product
            </span>
            <div className='inline-grid gap-6 grid-cols-2 relative'>
                {service?.projectServices?.map((service) => {
                    return (
                        <div data-aos={service?.animationFading} className={`${service?.backgroundGradient} p-9 rounded-[30px] items-center flex flex-col gap-2`}>
                            <div className='flex items-center gap-0'>
                                <img className='w-[93px] h-[71px]' src={service.serviceImg1} alt=''/>
                                {service?.serviceImg2 && <img className='w-[93px] h-[71px]' src={service?.serviceImg2} alt=''/>}
                            </div>
                            <span className='mt-3 text-[25px] leading-[32px] font-[500] text-center font-sans'>
                                {service.title}
                            </span>
                        </div>
                    )
                })}
            </div>  
        </div>

    )
}

export default ProjectServices

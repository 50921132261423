import React, { useRef, useState } from 'react'
import styles from './style.module.scss'

const CareerIntro = () => {
    const videoRefs = useRef([]);
    const [pausedStates, setPausedStates] = useState({});

    const handlePlayPause = () => {
        const video = videoRefs.current;
        if (video.paused) {
            videoRefs?.current?.forEach((v) => {
                v.pause()
            })
            video.play();
        } else {
            video.pause();
        }
        setPausedStates((prevPausedStates) => {
            let newPausedStates = { ...prevPausedStates };
            newPausedStates = video.paused;
            return newPausedStates;
        });
    };
    const handleFullScreen = () => {
        const video = videoRefs.current;
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) { /* Firefox */
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE/Edge */
            video.msRequestFullscreen();
        }
    };


    return (
        <div className='relative'>
            <div data-aos="fade-up" className={`p-auto md:p-[45px] mb-[45%] md:mb-[17%] mt-[15%] md:mt-auto relative ${styles.videoContainer}`} >

                {/* <p data-aos="zoom-in" className=' text-[9vw] font-bold tracking-[15px] z-[1] text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000000_-140%,_rgba(0,_0,_0,_0)_79%)] uppercase mb-auto md:mb-[-70px] '>
                    Career
                </p>
                <div className='relative w-[250px] md:w-[603px]'>
                    <div className='flex items-center justify-center'>
                        <div onClick={() => handlePlayPause()}
                            className={`${styles.playPauseButton} cursor-pointer z-[1]`}>
                            <img className={`${pausedStates === false ? '' : 'h-[25px]'}`} src={pausedStates === false ? '/svg/pause.svg' : '/svg/play.svg'} alt='pause' />
                        </div>
                        <img data-aos="fade-up" onClick={() => handleFullScreen()} className={`absolute right-[4%] top-[3%]  cursor-pointer z-[1] h-[40px]`} src={'/svg/full-screen.svg'} alt='fullscreenIcon' />
                        <video data-aos="fade-up" ref={(el) => (videoRefs.current = el)} controls={false} className='rounded-[30px] object-cover aspect-[0.87] w-[250px] h-[250px] md:w-[603px] md:h-[595px] '>
                            <source src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video></div>
                </div> */}
                {/* <div className='relative'> */}
                    <div data-aos="fade-right" className=' left-[89px] md:left-auto right-[0px] bottom-[-106px] md:bottom-[-75px] items-end z-[1]'>
                        <div className='realtive'>
                            <p data-aos="zoom-in" className=' text-[8vw] font-bold tracking-[6px] z-[1] text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000000_-100%,_rgba(0,_0,_0,_0)_79%)] uppercase mb-[-10px] md:mb-[-45px] border-double'>
                                Career
                            </p>
                        </div>
                        <div className='flex flex-col [background:_linear-gradient(to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] [backdrop-filter:_blur(2px)] rounded-[20px] p-4 md:p-10 w-auto md:w-full text-black font-sans'>
                            <span className='font-[500] text-[20px] md:text-[25px] md:leading-[38px] font-bold'>
                                It's Time to Reshape your Career with TCS
                            </span>
                            <span className='font-[400] text-[16px] md:text-[20px] md:leading-[34px] '>Ready to make your mark? Reach out today and let’s ignite your startup’s journey to success!</span>
                        </div>
                        <div className='w-[100%] flex justify-end md:w-auto mt-[-56px]'>
                            <img src='/svg/hi-fi-icon.svg' alt='hi-fi-icon' className='animate-swing w-[26%] md:w-auto ' />
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default CareerIntro

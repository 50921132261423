import React, { useState } from 'react';
import styles from './style.module.scss'
import ButtonComponent from '../../atoms/Button';
import { message } from 'antd';
import commonConstants from '../../../utils/constants';

const SubmitForm = () => {

    const [formData, setFormData] = useState({
        FirstName: '',
        Email: '',
        PhoneNumber: '',
        Message: ''
    })

    const [loading, setLoading] = useState(false);

    const onChangeForm = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const onSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            const sheetFormData = new FormData();

            sheetFormData.append("FirstName", formData?.FirstName);
            sheetFormData.append("Email", formData?.Email);
            sheetFormData.append("PhoneNumber", formData?.PhoneNumber);
            sheetFormData.append("Message", formData?.Message);

            const res = await fetch(commonConstants.google_sheet_deployed_url, {
                method: "POST",
                body: sheetFormData
            });

            setLoading(false);
            setFormData({
                FirstName: '',
                Email: '',
                PhoneNumber: '',
                Message: ''
            });

            if (res.status === 200) {
                message.success({
                    content: "Thank you for showing interest in The Coding Studio, We will contact you soon."
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    const isButtonDisabled = !formData.FirstName || !formData.Email || !formData.PhoneNumber;

    return (
        <div className='w-[100%] lg:w-[70%] flex justify-center items-center'>
            <div className='flex gap-10 flex-col justify-center items-center'>
                <div data-aos="fade-left" className='flex flex-col md:flex-row gap-6 w-[100%]'>
                    <label className='flex flex-col gap-2 w-[100%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'>Name *</span>
                        <input onChange={onChangeForm} value={formData?.FirstName} className={`${styles.themeInput}`} type="text" name="FirstName" placeholder='Name' />
                    </label>
                    {/* <label className='flex flex-col gap-2 w-[100%]' >
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'> Last Name</span>
                        <input onChange={onChangeForm} value={formData?.LastName} className={`${styles.themeInput}`} type="text" name="LastName" placeholder='Last Name' />
                    </label> */}
                </div>
                <div data-aos="fade-left" className='flex flex-col md:flex-row gap-6 w-[100%]'>
                    <label className='flex flex-col gap-2 w-[100%]'>
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'> Email ID *</span>
                        <input onChange={onChangeForm} value={formData?.Email} className={`${styles.themeInput}`} type="email" name="Email" placeholder='Email Id' />
                    </label>
                    <label className='flex flex-col gap-2 w-[100%]' >
                        <span className='text-[17px] leading-[21px] font-[400] font-sans'> Phone Number *</span>
                        <input onChange={onChangeForm} value={formData?.PhoneNumber} className={`${styles.themeInput}`} type="number" name="PhoneNumber" placeholder='Phone Number' />
                    </label>
                </div>
                <div data-aos="fade-left" className='w-[100%] flex flex-col gap-2 w-[100%] '>
                    <div className="text-[17px] leading-[21px] font-[400] font-sans">Message</div>
                    <textarea
                        id={''}
                        onChange={onChangeForm}
                        value={formData?.Message}
                        name="Message"
                        rows={5}
                        placeholder='Type your message here...'
                        className={`w-[100%] ${styles.themeTextArea}`}>
                    </textarea></div>
                <div data-aos="fade-left" className='mt-[40px]'>
                    <ButtonComponent disabled={isButtonDisabled} loading={loading} iconClassName="absolute right-[15px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] ml-[-10px] leading-[24px] font-[500] font-sans" inputStyles={{ minWidth: '220px', width: '220px', height: '60px' }} label={"Submit"} onClick={onSubmit} />
                </div>
            </div>
        </div>
    )
}

export default SubmitForm

import React from 'react'
import UnderLineText from '../../atoms/UnderlineText'
import { motion } from 'framer-motion'
import commonConstants from '../../../utils/constants'

const CareerBenefits = () => {
  return (
    <div className='relative'>
      <div className='flex flex-col gap-[10px] md:gap-[30px] mt-[100px] relative'>
        <UnderLineText text={'Career Growth'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
        <span data-aos="fade-up" className='sub-title'>Benefits</span>
      </div>
      <div className='flex-col md:flex-row flex gap-[2%] mt-[45px] relative '>
        {
          commonConstants.CareerBenefit.map((Benefits, idx) => {
            return (
              <div data-aos="fade-up" key={idx} className={`bg-white md:[background:_linear-gradient(to_bottom,_#FFFFFF_0%,rgb(255,255,255,0)_100%)] p-5 rounded-[30px] items-center flex flex-col gap-2 justify-center w-auto md:w-[334px] h-[200px] md:h-[252px] ${Benefits?.marginTop} mb-[6.5%] md:mb-auto `}>
                <motion.img className='w-[57px] h-[48px]' src={Benefits.image}
                  animate={{ y: [0, -20, 0] }}
                  transition={{
                    duration: 0.6,
                    repeat: 4,
                    repeatType: "loop",
                  }} />
                <span className='mt-3 text-[17px] leading-[21px] font-[500] text-center font-sans'>
                  {Benefits.title}
                </span>
                <span className='text-center text-[14px] leading-[21px] font-[400] font-sans'>
                  {Benefits.description}
                </span>
              </div>
            )
          })
        }
      </div>

      <motion.div variants={{
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.25
          }
        }
      }} data-aos="fade-up" className='absolute right-[0%] lg:right-[-9.4%] top-[0%] lg:top-[9%]'>
        <motion.img animate={{ y: [0, -20, 0] }}
          transition={{
            duration: 0.6,
            repeat: 4,
            repeatType: "loop",
          }} src='/svg/space-jet.svg' className='w-[200px] h-[200px] lg:w-[255px] lg:h-[255px] ' />
      </motion.div>

    </div>
  )
}

export default CareerBenefits

import React, { useState } from 'react'
import UnderLineText from '../../atoms/UnderlineText'
import ButtonComponent from '../../atoms/Button'
import commonConstants from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const ProfilePortfolio = ({ profileScreen, title }) => {
    const navigate = useNavigate()
    const [visibleCount, setVisibleCount] = useState(4);
    const [showAll, setShowAll] = useState(false);

    const viewAllProject = () => {
        if (showAll) {
            setVisibleCount(4);
            setShowAll(false);
        } else {
            setVisibleCount(commonConstants.ProjectsList?.length);
            setShowAll(true);
        }
    };

    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <div className='flex justify-between items-center relative'>
                <div className='flex flex-col gap-[30px]'>
                    <UnderLineText text={'Our Portfolio'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
                    <span data-aos="fade-up" className='sub-title font-sans'>
                        {title}
                    </span>
                </div>
                {profileScreen ? <img data-aos="fade-up" src='/svg/space-jet-left.svg' className='w-[45%] md:w-[255px] h-auto md:h-[255px] absolute top-[0px] md:top-[-116px] right-[-20px] md:right-[50px]' alt='' /> : ""}
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-[100px] mt-[20px]'>
                {commonConstants.ProjectsList.slice(0, visibleCount).map((project, idx) => {
                    return (
                        <div className='relative'>
                            <div className='cursor-pointer'>
                                <img data-aos="fade-up" src={project.projectImage} className={`bg-white w-[100%] h-[450px] md:h-[598px] object-cover  ${project.borderRadius} `} onClick={() => { navigate('/project-details', { state: project }) }} alt=''/>
                                <div data-aos="fade-up" className='flex gap-4 absolute top-[25px] right-[103px]'>
                                    {project?.projectLanguageIcon?.map((languageIcon) => {
                                        return (
                                            <div className='w-[73px] h-[73px] flex items-center justify-center shadow-[0px_4px_34px_0px_#00000026] [background:white] rounded-[50%] [border:1px_solid_#0000004D]'>
                                                <img src={languageIcon} alt='languageIcon' className='w-[3rem] h-[3rem]'/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div data-aos="fade-up" className='flex justify-between items-center py-[20px] md:py-[30px]'>
                                <div className='text-[35px] font-[500] leading-[44px] cursor-pointer'>{project.title}</div>
                                <div className='flex justify-end gap-3'>
                                    {/* {project?.images?.map((projectImage, idx) => {
                                        return (
                                            <ImageWithBG animation={"fade-left"} className={"w-[15%]"} src={projectImage} />
                                        )
                                    })} */}
                                </div>
                            </div>
                            <div data-aos="fade-up" className='text-[16px] md:text-[18px] font-[400] leading-[27px] md:leading-[33px]'>{project.description}</div>
                        </div>
                    )
                })}
            </div>
            {commonConstants.ProjectsList?.length > 4 && <div data-aos="fade-up" className='flex items-center justify-center mt-[50px]'>
                <ButtonComponent iconClassName="absolute right-[35px] "
                    iconPath="/svg/right-white-arrow.svg"
                    labelClassname="text-[24px] ml-[-10px] leading-[24px] font-[500] font-sans"
                    inputStyles={{ minWidth: '341px', width: '341px', height: '67px', boxShadow: 'none', borderRadius: '20px' }}
                    label={!showAll ? "View all Projects" : "View less"}
                    onClick={viewAllProject} />
            </div>}
        </div>
    )
}

export default ProfilePortfolio

import React from 'react'

const CeoMessage = () => {
    return (
        <div className='flex flex-col gap-4 justify-between '>
            <span className="text-[35px] leading-[44px] font-[700] mb-[2rem]">
                Message from <span className="text-theme">CEO</span>
            </span>
            <div className='flex flex-col md:flex-row gap-14 '>
                <img animation={"flip-down"} src="/images/profile-pic.png" className={"md:w-[40%]"} alt=''/>
                <span className="text-[18px] md:text-[20px] leading-[30px] md:leading-[32px] font-[400]">
                    Dear Valued Clients and Partners,
                    Over the past decade, our company has been dedicated to transforming the tech landscape for startups and businesses. We started with a simple yet powerful vision: to harness the power of technology to drive innovation, efficiency, and growth for companies of all sizes. Today, I am proud to say that we have made significant strides in achieving that vision.
                    <span className='hidden md:hidden lg:block 2xl:block xl:block'> <br />  Our journey has been marked by relentless innovation, unwavering commitment, and deep collaboration with our clients. We believe that technology is not just a tool but a catalyst for change, and our mission is to ensure that every business we work with can leverage this potential to its fullest. Whether you are a startup striving to make your mark or an established business looking to scale new heights, we are here to support you every step of the way.</span>
                    <span className='hidden md:hidden md:hidden lg:hidden 2xl:block'>   <br />In the ever-evolving world of technology, we remain steadfast in our commitment to quality, reliability, and sustainability. We are constantly exploring new frontiers, integrating cutting-edge advancements into our solutions, and ensuring that our clients stay ahead of the curve.</span>
                    <span className='hidden md:hidden md:hidden lg:hidden 2xl:block'>   <br /> As we look to the future, we are excited about the endless possibilities that lie ahead. We will continue to innovate, empower, and collaborate, driving meaningful change and creating lasting value for our clients. Thank you for being a part of our journey.</span>

                </span>
            </div>
            <div data-aos="fade-left" className='flex gap-8 flex-col'>

                <span className="text-[18px] md:text-[20px] leading-[30px] md:leading-[32px] font-[400]">
                    <span className='md:block lg:hidden 2xl:hidden xl:hidden'>  Our journey has been marked by relentless innovation, unwavering commitment, and deep collaboration with our clients. We believe that technology is not just a tool but a catalyst for change, and our mission is to ensure that every business we work with can leverage this potential to its fullest. Whether you are a startup striving to make your mark or an established business looking to scale new heights, we are here to support you every step of the way.</span>
                    <span className='2xl:hidden'> In the ever-evolving world of technology, we remain steadfast in our commitment to quality, reliability, and sustainability. We are constantly exploring new frontiers, integrating cutting-edge advancements into our solutions, and ensuring that our clients stay ahead of the curve.</span>
                    <span className='2xl:hidden'>  <br /> <br /> As we look to the future, we are excited about the endless possibilities that lie ahead. We will continue to innovate, empower, and collaborate, driving meaningful change and creating lasting value for our clients. Thank you for being a part of our journey. </span>
                </span>
                <div className='border-theme border-b-[1px] w-[40%]' />
                <span className="text-[28px] md:text-[32px] leading-[38px] font-[600]">
                    Mr. Himanshu Goswami
                </span>
                <span className="mt-[-1rem] text-[23px] leading-[29px] font-[400]">
                    CEO & Founder
                </span>
            </div>
        </div>
    )
}

export default CeoMessage

import React from 'react';

const UnderLineText = ({ text, className, isThickUnderline, bgClassName, textClassname, animation, isGreenLine, isBlueLine, isLightThemeLine }) => {
    const getUnderlineBG = () => {
        if (isGreenLine) {
            return 'bg-green-underline';
        } else if (isBlueLine) {
            return 'bg-blue-underline';
        } else if (isLightThemeLine) {
            return 'bg-light-theme-underline';
        } else {
            return 'bg-theme-underline';
        }
    }
    return (
        <span data-aos={animation || 'fade-right'} className={`${bgClassName ? bgClassName : 'bg-bottom mx-[-12px] px-3'} w-max bg-no-repeat ${isThickUnderline ? 'bg-theme-underline-thick' : getUnderlineBG()} ${textClassname || ''} ${className || ''}` }>
            {text}
        </span>
    )
}

export default UnderLineText
import React from 'react'
import UnderLineText from '../../atoms/UnderlineText'

const LanguageWrapper = ({ background }) => {
    return (
        <div className={`flex flex-col items-center bg-[center] [background-size:100%] p-[10%_4%_18%_10%] ${background} bg-no-repeat w-[100%] relative z-[1]`}>
            <div data-aos="zoom-in" className='relative w-[70%] text-center'>
                <img data-aos="fade-right" className='w-[24%] md:w-[10%] absolute left-[-22%] md:left-[-7%] md:top-0 top-[-33px]' src='/images/profile-pic.png' alt='profile' />
                <span className='font-[500] text-[4vw]'>A Complete Multi-Layer </span>
                <span className='font-[600] font-[Fredoka] text-[4vw]'>Development </span>
                <span className='font-[500] text-[3vw]'>For </span>
                <span className='font-[500] tracking-[0.5px] font-[Fredoka] text-theme text-[4vw]'>Software </span>
                <UnderLineText bgClassName={"bg-right-bottom mx-[-12px] px-3 [background-size:60%]"} textClassname={"font-[500] text-[4vw]"} text={"And Technology"} />
                <img data-aos="fade-left" className='w-[24%] md:w-[10%] absolute right-[-16%] md:right-[-7%] top-[-38%] md:top-0 animate-swing' src='/svg/hand-wave.svg' alt='wave' />
            </div>
        </div>
    )
}

export default LanguageWrapper

import React from 'react'
import UnderLineText from '../../../atoms/UnderlineText'

const LanguageServices = ({ service, titleDes, title }) => {
    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={title} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                {titleDes}
            </span>
            <div className='inline-grid gap-6 grid-cols-1 md:grid-cols-2 relative'>
                {service?.langServices?.map((langService) => {
                    return <div data-aos={langService?.animation} className={`${langService?.backgroundServiceGradient} p-9 rounded-[30px] items-center flex flex-col gap-2`}>
                        <span className='mt-3 text-[27px] leading-[34px] font-[500] text-center font-sans'>
                            {langService?.serviceTitle}
                        </span>
                    </div>
                })}
            </div>
        </div>
    )
}

export default LanguageServices
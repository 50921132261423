import React, { useState } from 'react'
import ButtonComponent from '../../atoms/Button'
import UnderLineText from '../../atoms/UnderlineText'
import commonConstants from '../../../utils/constants';
import { motion } from 'framer-motion'

const OurGallary = () => {
    const [visibleCount, setVisibleCount] = useState(6);
    const [showAll, setShowAll] = useState(false);

    const viewAllPictures = () => {
        if (showAll) {
            setVisibleCount(6);
            setShowAll(false);
        } else {
            setVisibleCount(commonConstants.galleryImage?.length);
            setShowAll(true);
        }
    };

    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText className={"px-2 md:mx-[100px]"} isGreenLine={true} text={'Our Gallery'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-right" className='sub-title p-2 md:px-[100px]'>
                Life at TheCodingStudio
            </span>
            <img data-aos="zoom-in" className='w-[100%] h-[80%]' src='/images/office-images/navratri.jpg' alt='team' />
            <div className='flex flex-col items-center gap-[120px] mt-[-20px] mx-[20px] md:mx-[50px]'>
                <div className='grid grid-cols-2 md:grid-cols-3 gap-[3%] w-[100%] items-center justify-center w-[90%] h-[50%]'>
                    {commonConstants.galleryImage.slice(0, visibleCount).map((image, idx) => (
                        <motion.img
                            data-aos='zoom-in-up'
                            key={idx}
                            className={`w-[90%] h-[400px] outline-4 outline-[#FF5D224D] outline-dashed outline-offset-[4px] md:outline-offset-[12px] rounded-[50%] mt-[50px]`}
                            src={image.image}
                            alt={image.alt}
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
                            }}
                        />
                    ))}
                </div>
                {/* {visibleCount > 3 && (
                    <div className='flex gap-[8%] w-[100%] items-center justify-center'>
                        {commonConstants.galleryImage.slice(3, visibleCount).map((image, idx) => (
                            <motion.img
                                key={idx + 3} 
                                className={`w-[25%] outline-4 outline-[#FF5D224D] outline-dashed outline-offset-[12px] rounded-[50%] `}
                                src={image.image}
                                alt={image.alt}
                                initial="hidden"
                                animate="visible"
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
                                }}
                            />
                        ))}
                    </div>
                )} */}
                {commonConstants.galleryImage?.length > 6 && <> <ButtonComponent
                    data-aos="fade-up"
                    iconClassName="absolute right-[15px]"
                    iconPath="/svg/right-white-arrow.svg"
                    labelClassname="text-[18px] ml-[-10px] leading-[24px] font-[500]"
                    inputStyles={{ minWidth: '200px', width: '200px', height: '50px' }}
                    label={!showAll ? "View all" : "View less"}
                    onClick={viewAllPictures}
                /></>}
            </div>
        </div>
    )
}

export default OurGallary
import React, { useRef, useState } from 'react'
import Slider from 'react-slick';
import commonConstants from '../../../utils/constants'
import UnderLineText from '../../atoms/UnderlineText'
import styles from './style.module.scss'
import { useInView, motion } from 'framer-motion';

const Testimonials = ({ clients }) => {

    const ref = useRef(null);
    const isInView = useInView(ref);
    const videoRefs = useRef([]);
    const [pausedStates, setPausedStates] = useState({});
    const [visibleVideos, setVisibleVideos] = useState(new Array(clients?.length).fill(false))

    const testimonialsVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };
    const handlePlayPause = (index) => {
        const video = videoRefs.current[index];
        if (video.paused) {
            videoRefs.current.forEach((v) => {
                v.pause()
            })
            video.play();
        } else {
            video.pause();
        }
        setPausedStates((prevPausedStates) => {
            const newPausedStates = { ...prevPausedStates };
            newPausedStates[index] = video.paused;
            return newPausedStates;
        });
    };


    const handleToggleVideo = (idx) => {
        const newVisibleVideos = [...visibleVideos];
        newVisibleVideos[idx] = !newVisibleVideos[idx];
        setVisibleVideos(newVisibleVideos);
    };

    const handleFullScreen = (index) => {
        const video = videoRefs.current[index];
        if (video?.requestFullscreen) {
            video?.requestFullscreen();
        } else if (video?.mozRequestFullScreen) { /* Firefox */
            video?.mozRequestFullScreen();
        } else if (video?.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            video?.webkitRequestFullscreen();
        } else if (video?.msRequestFullscreen) { /* IE/Edge */
            video?.msRequestFullscreen();
        }
    };

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img className='absolute md:w-auto w-[8%] right-0 top-[-34px] md:top-[-80px] cursor-pointer'
                onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-next.svg' : '/svg/enabled-next.svg'}
                alt='next-arrow' />
        );
    }

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img className='absolute md:w-auto w-[8%] right-[64px] md:right-[80px] top-[-34px] md:top-[-80px] cursor-pointer' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-previous.svg' : '/svg/enabled-previous.svg'} alt='prev-arrow' />
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        // slidesToScroll: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 640, //'md' breakpoint
                settings: {
                    slidesToShow: 1.02
                }
            },
            {
                breakpoint: 1024, // 'lg' breakpoint
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    return (
        <motion.div ref={ref}
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            variants={testimonialsVariants} className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={'Testimonials'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <div data-aos="fade-up" className='font-[500] text-[25px] md:text-[35px] leading-[44px] font-sans'>
                What customers say about us
            </div>
            <Slider {...settings}>
                {
                    commonConstants.clientTestimonials.map((client, idx) => {
                        return (
                            <div data-aos="fade-up" className={`p-[45px] relative ${styles.videoContainer}`} key={idx}>
                                {/* <div onClick={() => handlePlayPause(idx)} className={`${styles.playPauseButton} hidden cursor-pointer z-[1]`}>
                                    <img className={`${pausedStates[idx] === false ? '' : 'h-[25px]'}`} src={pausedStates[idx] === false ? '/svg/pause.svg' : '/svg/play.svg'} alt='play&pause' />
                                </div>
                                <img onClick={() => handleFullScreen(idx)} className={`${styles.fullScreenButton} cursor-pointer z-[1] h-[40px]`} src={'/svg/full-screen.svg'} alt='fullScreenBtn' />
                                <video ref={(el) => (videoRefs.current[idx] = el)} controls={false} className='rounded-[30px] object-cover aspect-[0.87]'>
                                    <source src={client.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='absolute text-white flex flex-col gap-2 right-[0px] bottom-[20px] bg-black/60 [backdrop-filter:_blur(2px)] rounded-[20px] p-4 w-[70%]'>
                                    <span className='font-[400] text-[26px] leading-[28px]'>
                                        Client: <b>{client.name}</b>
                                    </span>
                                    <Tooltip title={client.content}>
                                        <span className='font-[500] line-clamp-2 text-[16px] leading-[21px]'>
                                            {client.content}
                                        </span>
                                    </Tooltip>
                                </div> */}
                                <div className="flex flex-col overflow-hidden rounded-[2rem]">
                                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7 rounded-[2rem] shadow-xl">
                                        <div className="flex-1">
                                            <div className="flex items-center gap-2">
                                                <img src='/svg/star.svg' alt='star' />
                                                <img src='/svg/star.svg' alt='star' />
                                                <img src='/svg/star.svg' alt='star' />
                                                <img src='/svg/star.svg' alt='star' />
                                                <img src='/svg/star.svg' alt='star' />
                                            </div>
                                            {/* <Tooltip title={client.content}> */}
                                            <blockquote className="flex-1 mt-8">
                                                <p className="text-md text-left md:text-lg leading-relaxed text-gray-900 font-pj md:text-center">{client.content}</p>
                                            </blockquote>
                                            {/* </Tooltip> */}
                                        </div>

                                        <div className="flex flex-col items-center justify-center mt-8">
                                            <p className="text-2xl font-bold text-gray-900 font-pj text-center">{client.name}</p>

                                            {client?.video &&
                                                <div onClick={() => handleToggleVideo(idx)} className='w-full flex justify-end items-end cursor-pointer mt-4 text-theme font-semibold'>
                                                    See Video</div>
                                            }
                                            {client?.video && visibleVideos[idx] && <div className='relative'>
                                                <div onClick={() => handlePlayPause(idx)} className={`${styles.playPauseButton} hidden cursor-pointer z-[1]`}>
                                                    <img className={`${pausedStates[idx] === false ? '' : 'h-[25px]'}`} src={pausedStates[idx] === false ? '/svg/pause.svg' : '/svg/play.svg'} alt='play&pause' />
                                                </div>
                                                <img onClick={() => handleFullScreen(idx)} className={`${styles.fullScreenButton} cursor-pointer z-[1] h-[40px]`} src={'/svg/full-screen.svg'} alt='fullScreenBtn' />
                                                <video ref={(el) => (videoRefs.current[idx] = el)} controls={false} className='rounded-[30px] object-cover aspect-[0.87]'>
                                                    <source src={client?.video} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video></div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </motion.div>
    )
}

export default Testimonials
import React, { useEffect, useState } from 'react';
import commonConstants from '../../../utils/constants';
import { motion } from 'framer-motion';

const Footer = () => {
    const [link, setLink] = useState(false);
    const [services, setServices] = useState(false);
    const [ismobile, setIsmobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsmobile(window.innerWidth < 700);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollToTopFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className='flex flex-col text-white mt-auto'>
            <div className="bg-[#1A2934] p-[20px] md:p-[50px] relative">
                <div className='flex flex-col md:flex-row gap-[20px] items-start justify-center md:justify-evenly md:items-start'>
                    <div className='flex flex-col gap-2 max-w-[280px]'>
                        <img src='/svg/logo-white.svg' className='w-[150px] h-[28px]' alt='logo' />
                        <span className='font-[400] leading-[24px] md:leading-[33px] mt-2 text-wrap'>
                            Empowering your digital transformation with innovative solutions.
                            {/* Building the future of web and software development. */}
                            {/* Delivering innovative IT solutions for a digital world. */}
                            {/* Crafting exceptional digital experiences for your business. */}
                            {/* Transforming ideas into reality with technology. */}
                            {/* Your trusted partner in tech innovation and development. */}
                        </span>
                        <div className='flex gap-2 mt-[20px] md:mt-[30px]'>
                            {/* <a href='/' target='_blank' rel="noreferrer" className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-[#D3D3D3] w-[40px] h-[40px] md:w-[50px] md:h-[50px]'>
                                <img src='/svg/facebook-theme.svg' alt="facebook" />
                            </a> */}
                            <a href='https://www.instagram.com/thecodingstudio/' target='_blank' rel="noreferrer" className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-[#D3D3D3] w-[40px] h-[40px] md:w-[50px] md:h-[50px]'>
                                <img src='/svg/instagram-theme.svg' alt="instagram" />
                            </a>
                            <a href='https://in.linkedin.com/company/thecodingstudio' target='_blank' rel="noreferrer" className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-[#D3D3D3] w-[40px] h-[40px] md:w-[50px] md:h-[50px]'>
                                <img src='/svg/linkedin-theme.svg' alt="linkedin" />
                            </a>
                            <a href='https://x.com/thecodingstudio?s=21&t=Mo5GoJa54ooyjNxW5dQrwA' target='_blank' rel="noreferrer" className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-[#D3D3D3] w-[40px] h-[40px] md:w-[50px] md:h-[50px]'>
                                <img src='/svg/twitter-theme.svg' alt="twitter" />
                            </a>
                        </div>
                    </div>

                    <div className='flex flex-col gap-1 w-full md:w-auto'>
                        <div className='flex justify-between items-center w-full'>
                            <div className='font-[700] md:mb-5 leading-[24px] md:leading-[28px] text-[18px] md:text-[22px]'>
                                Useful Links
                            </div>
                            <div className='text-theme text-[45px] leading-[57px] font-[700] md:hidden' onClick={() => { setLink(!link) }}>+</div>
                        </div>
                        {ismobile ? (
                            link && (<><a href='/about-us' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                About us
                            </a>
                                <a href='/case-studies' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Case Studies
                                </a>
                                <a href='/gallery' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Gallery
                                </a></>)
                        ) : (
                            <><a href='/about-us' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                About us
                            </a>
                                <a href='/case-studies' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Case Studies
                                </a>
                                <a href='/gallery' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Gallery
                                </a></>
                        )}
                    </div>

                    <div className='flex flex-col gap-1 w-full md:w-auto'>
                        <div className='flex justify-between items-center w-full'>
                            <div className='font-[700] md:mb-5 leading-[24px] md:leading-[28px] text-[18px] md:text-[22px]'>
                                Our Services
                            </div>
                            <div className='text-theme text-[45px] leading-[57px] font-[700] md:hidden' onClick={() => { setServices(!services) }}>+</div>
                        </div>
                        {ismobile ? (
                            services && (
                                <>
                                    <a href='/services/frontend' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                        Frontend Development
                                    </a>
                                    <a href='/services/backend' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                        Backend Development
                                    </a>
                                    <a href='/services/fullstack' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                        Fullstack Development
                                    </a>
                                    <a href='/services/digital-solutions' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                        Digital Solutions
                                    </a>
                                </>
                            )
                        ) : (
                            <>
                                <a href='/services/frontend' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Frontend Development
                                </a>
                                <a href='/services/backend' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Backend Development
                                </a>
                                <a href='/services/fullstack' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Fullstack Development
                                </a>
                                <a href='/services/digital-solutions' className='font-[400] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px] cursor-pointer'>
                                    Digital Solutions
                                </a>
                            </>
                        )}
                    </div>

                    <div className='flex flex-col gap-2'>
                        <span className='font-[700] mb-2 md:mb-5 leading-[24px] md:leading-[28px] text-[18px] md:text-[22px]'>
                            Contact info
                        </span>
                        <div className='flex gap-2 md:gap-3 items-center'>
                            <img src='/svg/call.svg' className='animate-pulse' alt='call' />
                            <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:${commonConstants.company_number}`}>
                                {commonConstants.company_number}
                            </a>
                        </div>
                        <div className='flex gap-2 md:gap-3 items-center'>
                            <img src='/svg/mail.svg' alt='mail' />
                            <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`mailto:${commonConstants.company_email}`}>
                                {commonConstants.company_email}
                            </a>
                        </div>
                        <div className='flex gap-2 md:gap-3 items-start max-w-[350px]'>
                            <img src='/svg/location.svg' className='animate-pulse' alt='address' />
                            <span className='font-[500] leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]'>
                                {commonConstants.company_address}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex items-end md:items-center justify-end mt-5 md:mt-0' onClick={scrollToTopFunction} id='myBtn'>
                    <div className='flex items-center justify-center w-[50px] h-[50px] md:w-[66px] md:h-[66px] bg-[#FF5D22] rounded-[50%] cursor-pointer'>
                        <motion.img animate={{ y: [0, -10, 0] }} transition={{ repeat: Infinity, duration: 1 }} src='/svg/scrollToTop-icon.svg' alt='scrollIcon' />
                    </div>
                </div>
            </div>
            <div className='h-[65px] bg-theme flex justify-center items-center text-center md:text-left'>
                <img src='/svg/copyright.svg' alt='copyRight' />
                <p className='font-[500] text-[14px] md:text-[20px] leading-[24px] md:leading-[33px] md:ml-2'>
                    2024 THECODINGSTUDIO | All rights reserved
                </p>
            </div>
        </div>
    );
}

export default Footer;
import React from 'react'
import SubmitForm from '../SubmitForm/SubmitForm'
import { useLocation } from 'react-router-dom'

const JoinUsForm = () => {
    const location = useLocation();
    const { pathname } = location;

    return (
        <div className='px-[10%] py-[4%] bg-light-theme-light-gradient flex flex-col gap-5 items-center justify-center'>
            <span className='text-[37px] font-[700] leading-[47px] mb-[70px]'>
                {pathname === '/career' ? 'Join Us' : 'Get Your Quotation Today'}
            </span>
            <SubmitForm />
        </div>
    )
}

export default JoinUsForm   
import React from 'react'
import OurTeam from '../components/organisms/OurTeam'
import OurHead from '../components/organisms/OurHead'
import OurAlumanis from '../components/organisms/OurAlumanis'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'

const TeamMates = () => {
    return (
        <div className='text-black pb-6 relative px-[100px]'>
            <div className='flex flex-col items-center bg-[center] [background-size:100%] p-[10%_4%_18%_10%] bg-team-mates bg-no-repeat w-[100%] relative z-[1]'>
                <div data-aos="zoom-in" className='relative w-[70%] text-center'>
                    <div className='absolute top-[-21%] left-[-21%]'>
                        <img data-aos="fade-right" src='/svg/team-standing-girl.svg' alt=''/>
                    </div>
                    <img data-aos="zoom-in" className=' w-[90%] pt-[10%]' src='/svg/team-mates-text.svg' alt=''/>
                    <div className='absolute top-[-41.5%] right-[-29%]'>
                        <img data-aos="fade-right" src='/svg/seating-boy.svg' alt=''/>
                    </div>
                </div>
            </div>
            {/* <OurHead /> */}
            {/* <OurTeam /> */}
            {/* <OurAlumanis /> */}
            <div className='mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default TeamMates

import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import commonConstants from '../../../utils/constants'
import ButtonComponent from '../../atoms/Button';
import ApplyNowModal from '../ApplyNowModal';

const CareerFaq = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showCareerApplyModal, setShowCareerApplyModal] = useState(false);
  const [modalWidth, setModalWidth] = useState('100%');

  const onChangeSelectedItem = (index) => {
    if (index === selectedIndex) {
      setShowCareerApplyModal(true)
    } else {
      setSelectedIndex(index)
    }
  }

  const onCloseModal = () => {
    setShowCareerApplyModal(false);
  }

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth >= 768) {
        setModalWidth('50%');
      } else {
        setModalWidth('100%');
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <>
      <Modal
        width={modalWidth}
        destroyOnClose={true}
        title={<div className='flex items-center justify-between mb-[2px] p-[10px] border-b-[1px] border-b-[#D3D3D3]'>
          <div className='text-[37px] font-[500]'>Apply Now</div>
          <img src='/svg/cancel-theme.svg' className='w-[24px] h-[24px] cursor-pointer' onClick={onCloseModal} /></div>}
        style={{ marginTop: '-50px' }} styles={{
          content: { borderRadius: '40px' },
          header: { textAlign: 'center' }
        }}
        closeIcon={false}
        open={showCareerApplyModal}
        footer={null}
        onCancel={onCloseModal}
      >
        <div className='max-h-[80vh] overflow-auto'>
          <ApplyNowModal role={commonConstants.DeveloperList.find((dev, idx) => idx === selectedIndex)} onCloseModal={onCloseModal} />
        </div>
      </Modal>
      <div data-aos="fade-up" className='flex flex-col gap-10' >
        {
          commonConstants.DeveloperList.map((dev, idx) => {
            const selected = idx === selectedIndex;
            return (
              <div className={`flex justify-between items-center border-2  backdrop-blur-sm rounded-2xl px-4 py-4 md:px-8 md:py-6 w-full text-black font-sans  cursor-pointer ${selected ? 'bg-white border-[#FFFFFF] shadow-[0px_14px_114px_0px_#0000001A]' : 'border-[#CDCDCD]'
                }`} key={idx} onClick={() => onChangeSelectedItem(idx)}>
                <div className='flex flex-col gap-2 w-[80%]' >
                  <span className='font-[500] text-[25px] leading-[32px] font-bold'>
                    {dev.label}
                  </span>
                  <span className='font-[400] text-[16px] md:text-[20px] md:leading-[34px] '>{dev.description}</span>
                </div>
                <div className={`${selected ? '[background:_#FF5D22]' : '[border:_2px_solid_#CDCDCD]'} rounded-[50%] w-[84px] sm:w-[54px] md:w-[74px] h-[50px] md:h-[74px] flex items-center justify-center cursor-pointer`}>
                  <img src={selected ? '/svg/up-cross-arrow.svg' : '/svg/right-arrow.svg'} className='w-[22px] md:w-auto' alt='' /></div>
              </div>
            )

          })}
      </div>

      <div data-aos="fade-up" className='flex flex-col items-center justify-center gap-12 mt-[100px]' >
        <div className='text-[24px] md:text-[30px] md:leading-[38px] font-[500] text-center font-sans'>Didn't find a suitable vacancy ? Send your resume on this email,<br />
          and we'll get back to you.</div>
        <div data-aos="fade-left" className='flex flex-col gap-10 relative'>
          <ButtonComponent labelClassname="text-[18px] font-[400] leading-[24px] flex items-center justify-center" inputStyles={{ minWidth: '286px', width: '286px', height: '67px', backgroundColor: '#FF5D224D', border: '1px solid #FF5D22', boxShadow: "none", color: "#FF5D22" }} label='Send Resume' onClick={() => { window.location.href = `mailto:${commonConstants.company_email}` }} />
          <ButtonComponent labelClassname="text-[18px] font-[700] leading-[24px] flex items-center justify-center" inputStyles={{ minWidth: '286px', width: '286px', height: '67px' }} label={commonConstants.company_email} onClick={() => { window.location.href = `mailto:${commonConstants.company_email}` }} />
          <img src='/svg/Arrow-turn.svg' className='h-[136px] absolute top-[30px] right-[-56px] md:right-[-88px]' alt='' />
        </div>
      </div>
    </>
  )
}

export default CareerFaq

import React from 'react';
import ContactUs from '../components/molecules/ContactUs';
import ProfilePortfolio from '../components/organisms/ProfilePortfolio/ProfilePortfolio';
import ProfileAboutUs from '../components/organisms/ProfileAboutUs/ProfileAboutUs';
import JoinUsForm from '../components/molecules/JoinUsForm';

const ProfilePage = ({state}) => {
    return (
        <div>
            <ProfileAboutUs profile={state} />
            <div className='px-2 lg:px-[100px]'>
                <ProfilePortfolio profileScreen={true} title={'Work Done By TheCodingStudio'} />
            </div>
            <div className='mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default ProfilePage

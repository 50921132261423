import React, { useState } from 'react'
import commonConstants from '../../../utils/constants';
import TabsComponent from '../../atoms/Tabs'
import UnderLineText from '../../atoms/UnderlineText'
import { motion } from 'framer-motion'

const Technologies = () => {
    const [tab, setTab] = useState('all');

    const onChangeTab = (key) => {
        setTab(key)
    }

    const filteredTechnologies = commonConstants.technologiesList.filter(tech => {
        if (tab === 'all') {
            return true
        }
        if (tech.role === '') {
            return true
        }
        return tech?.role === tab;
    });

    return (
        <div className='flex flex-col gap-[20px] mt-[100px] relative mb-[120px]'>
            <UnderLineText text={'Technologies'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className="sub-title font-sans">
                What technologies we provide
            </span>
            <TabsComponent
                data-aos="fade-up"
                activeKey={tab}
                animated={true}
                destroyInactiveTabPane={true}
                centered={true}
                onChange={onChangeTab}
                type="line"
                items={commonConstants?.technologyTabs.map(tab => (
                    { key: tab.key, label: tab.key })
                )}
                className='uppercase scroll text[15px] md:text-[23px]'
            />
            <motion.div
                variants={{
                    hidden: { opacity: 0 },
                    show: {
                        opacity: 1,
                        transition: {
                            staggerChildren: 0.25
                        }
                    }
                }}
                initial="hidden"
                animate="show"
                className="flex px-2 md:px-[20px] gap-8 flex-wrap justify-center my-5 mb-5"
            >
                {filteredTechnologies.map((tech, idx) => (
                    <div
                        key={tech.key}
                        className="relative"
                    >
                        <div className="themed-border rounded-[50%] animate-spin-slow absolute inset-0"></div>
                        <div
                            className="flex items-center justify-center w-[100px] h-[90px] rounded-[50%] relative"  >
                            <img src={tech.icon} alt={tech.name} className='w-[3rem] h-[3rem]' />
                        </div>
                    </div>
                ))}
            </motion.div>
        </div>
    )
}

export default Technologies
import React, { useState } from 'react'
import commonConstants from '../../../utils/constants'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import { Select } from 'antd';
import ButtonComponent from '../../atoms/Button';

const HeaderMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const [openNav, setOpenNav] = useState(false)
  return (
    <nav className={`flex items-center z-[10] justify-between p-[8px] gap-4 sticky top-[0] transition-all duration-500 bg-[#F8F8F8] shadow-[0px_14px_54px_0px_#00000021] fixed w-full`}>
      <div className="flex flex-wrap p-4 w-full">
        <div className={`flex items-center justify-between w-full ${openNav === true && '[border-bottom:_1px_solid_#e5e7eb] pb-2 '}`}>
          <a href="#" className="flex items-center space-x-3 ">
            <img src='/svg/logo.svg' className="h-8 w-40" alt="thecodingstudio Logo" />
          </a>
          <button data-collapse-toggle="navbar-hamburger" type="button" className="inline-flex items-center justify-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-200 dark:hover:bg-gray-200 dark:focus:ring-gray-200" aria-controls="navbar-hamburger" aria-expanded="false" onClick={() => {
            setOpenNav(!openNav)
          }}>
            <span className="sr-only">Open main menu</span>
            {openNav === false && <img src='/svg/nav-icon.svg' alt='' />}
            {openNav === true && <img src='/svg/cancel-theme.svg' alt='' />}
          </button>
        </div>
        {openNav && <div className="block w-full" id="navbar-hamburger">
          <ul className="flex flex-col font-medium mt-4 rounded-lg ">

            {commonConstants.headerItems.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  {
                    item.type === 'button' ?
                      <ButtonComponent inputStyles={{ width: '140px', marginTop: '20px' }} onClick={() => {
                        navigate('/getInTouch');
                        setOpenNav(false)
                      }} label={item.name} />
                      :
                      <div className='relative py-2 px-3'>
                        <div className={`dropdown-arrow w-full ${item.name === 'Services' && 'flex gap-6 items-center '}`} >
                          <NavLink to={item.path} className={`${pathname == item.path ? 'text-theme font-[700] text-[20px]' : 'text-[#292929] text-[18px]'} `} onClick={() => { setOpenNav(false) }}>
                            <a href="#" className={`${pathname == item.path ? 'text-theme font-[700] text-[20px]' : 'text-[#292929] text-[18px]'} `} aria-current={item.path}>{item.name}</a>
                          </NavLink>
                          {
                            item.key == 'services' &&
                            <div >
                              <Select
                                value={(pathname || '').split('/')[(pathname || '').split('/').length - 1]}
                                popupClassName='dropdown-container'
                                suffixIcon={<img src='/svg/dropdown-arrow-black.svg' />}
                                placement='bottomRight'
                                onChange={(val) => {
                                  navigate(`/services/${val.value}`);
                                  setOpenNav(false)
                                }}
                                options={commonConstants.headerServicesDropdownItems}
                                labelInValue={true}
                                labelRender={(props) => (<p className='hidden'></p>)}

                              /></div>
                          }
                        </div>
                        {/* {
                    item.key == 'career' &&
                    <div>
                      <div className='absolute border-b-[1px] border-theme rotate-[158deg] right-[4px] w-[42%]' />
                      <div className='absolute border-b-[1px] border-theme rotate-[21deg] left-[7px] w-[42%]' />
                      <div className='absolute right-[-25%] top-[90%] z-[1]'>
                        <div className={styles.hangingBoard}>
                          <div className={styles.board}>
                            <div className={styles.rope} />
                            <div className={styles.ropeLeftHang} />
                            <div className={styles.ropeRightHang} />
                          </div>
                          <div className={styles.text}>We are hiring</div>
                        </div>
                      </div>
                    </div>

                    
                  }  */}

                      </div>
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}
      </div>
    </nav>
  )
}

export default HeaderMobile

import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import SatisfactionServices from '../components/molecules/SatisfactionServices/SatisfactionServices'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'

const FrontendPage = () => {
    return (
        <div>
            <LanguageWrapper background={'bg-frontend-development'} />
            <ExperienceDesc title='frontend' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Frontend' language='frontend' filterKey='language'/>
            <Testimonials />
            {/* <LanguageTeam role="Frontend Developer" title="Frontend" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default FrontendPage
const commonConstants = {
    driveClientId: "91239488574-pe3rcq6i5pkfebtfo5e87uk3oa5g40od.apps.googleusercontent.com",
    driveAPIKey: "AIzaSyDq_WLqqk1rq5R0DV6mCmbxK80c-BNvNNU",
    driveFolderId: "1bdGKPoqELfrMPfpXhg1x-PCd2lxDcBe8",
    google_sheet_deployed_url: "https://script.google.com/macros/s/AKfycbzfMB2dBwQ6gipyWU33KyVnlcnF4TVpz0tLj2f4_04SQc323HV2zVHpePsrVHYwk-pEpQ/exec",
    company_number: "+917990345580",
    company_email: "hr@thecodingstudio.in",
    company_address: "436-437,The Boulevard, Near Pratham Circle, Gallaria Street Green city road , pal , Adajan, Surat, Gujarat 395009",
    headerItems: [
        {
            name: 'Home',
            key: 'home',
            path: '/',
            type: 'link'
        },
        {
            name: 'About us',
            key: 'aboutUs',
            path: '/about-us',
            type: 'link'
        },
        {
            name: 'Services',
            key: 'services',
            path: '/services',
            type: 'dropdown'
        },
        {
            name: 'Case Studies',
            key: 'Case Studies',
            path: '/case-studies',
            type: 'link'
        },
        {
            name: 'Career',
            key: 'career',
            path: '/career',
            type: 'link'
        },
        {
            name: 'Gallery',
            key: 'Gallery',
            path: '/gallery',
            type: 'link'
        },
        {
            name: 'Get in touch',
            key: 'getInTouch',
            type: 'button'
        }
    ],
    expertise: [
        {
            key: 1,
            number: '1',
            name: 'MVP Development',
        },
        {
            key: 2,
            number: '2',
            name: 'Product Ideation to Delivery',
        },
        {
            key: 3,
            number: '3',
            name: 'Web Apps development to streamline processes',
        },
        {
            key: 4,
            number: '4',
            name: 'Mobile apps to enhance user experience',
        }
    ],
    technologiesList: [
        {
            name: 'React',
            key: 'react',
            icon: '/svg/react.svg',
            role: 'frontend'
        },
        {
            name: 'CSS',
            key: 'css',
            icon: '/svg/css.svg',
            role: 'frontend'
        },
        {
            name: 'HTML',
            key: 'html',
            icon: '/svg/html.svg',
            role: 'frontend'
        },
        {
            name: 'NodeJS',
            key: 'node',
            icon: '/svg/node.svg',
            role: 'backend'
        },
        {
            name: 'Next.Js',
            key: 'NextJS',
            icon: '/svg/next-js.svg',
            role: 'frontend'
        },
        {
            name: 'GoLang',
            key: 'golang',
            icon: '/images/go-lang-logo.png',
            role: 'backend'
        },
        {
            name: 'Firebase',
            key: 'firebase',
            icon: '/images/firebase-logo.png',
            role: 'backend'
        },
        {
            name: 'MySQL',
            key: 'mysql',
            icon: '/svg/mysql.svg',
            role: 'backend'
        },
        {
            name: 'MongoDB',
            key: 'mongodb',
            icon: '/svg/mongo-db.svg',
            role: 'backend'
        },
        {
            name: 'Redis',
            key: 'redis',
            icon: '/images/redis-logo.png',
            role: 'backend'
        },

        {
            name: 'SalesForce',
            key: 'salesforce',
            icon: '/images/salesforce-logo.png',
            role: 'fullstack'
        },
        {
            name: 'AI/ML',
            key: 'ai/ml',
            icon: '/images/ai-ml.png',
            role: 'fullstack'
        },
        {
            name: 'GenAI',
            key: 'genAI',
            icon: '/images/genAi-logo.png',
            role: 'fullstack'
        },
        {
            name: 'DevOps',
            key: 'devops',
            icon: '/images/devops-logo.png',
            role: 'fullstack'
        },
        {
            name: 'Web3',
            key: 'web3',
            icon: '/images/web3-logo.png',
            role: 'fullstack'
        },
        {
            name: 'BlockChain',
            key: 'BlockChain',
            icon: '/images/blockchain-logo.png',
            role: 'fullstack'
        },
        {
            name: 'Angular',
            key: 'angular',
            icon: '/svg/angular.svg',
            role: 'frontend'
        },
        {
            name: 'Vue',
            key: 'vue',
            icon: '/svg/vue.svg',
            role: 'frontend'
        },
        {
            name: 'React Native',
            key: 'React Native',
            icon: '/svg/react.svg',
            role: 'frontend'
        },
        {
            name: 'Swift',
            key: 'Swift',
            icon: '/images/swift-og.png',
            role: 'frontend'
        },
        {
            name: 'Java',
            key: 'Java',
            icon: '/images/java.jpeg',
            role: 'frontend'
        },
        {
            name: 'Kotlin',
            key: 'Kotlin',
            icon: '/images/Kotlin_Icon.png',
            role: 'frontend'
        },
        {
            name: 'Flutter',
            key: 'Flutter',
            icon: '/images/flutter.png',
            role: 'frontend'
        },
    ],
    clientTestimonials: [
        {
            name: 'Sophronia Mckenzie',
            content: '" Great Job by TheCodingStudio, totally enjoyed working with them."',
            video: '/videos/visuEats-testimonials.MP4'
        },
        {
            name: 'Pavan Sinha',
            content: '" TheCodingStudio is a great app development company , who are easy to work with. They had high standards for work, quality of code and adhered to schedule well. They were flexible and arranged more time to help us meet deadlines. They made sure to send status everyday and inform of possible blockers beforehand. I would rehire them for all my future app projects. "',
        },
        {
            name: 'Jeremy',
            content: '" Took the brief and actioned it immediately, kept me involved at every step of the process. Will definitely work with TheCodingStudio again! "',
        },
        {
            name: 'Daniel',
            content: '" Great Job by TheCodingStudio, totally enjoyed working with them. It was the right time and right place. 😃 Will continue! Whereever possible. "',
        },
        {
            name: 'Atif M.',
            content: '" TheCodingStudio helped build my MVP frontend and it was amazing. They understood my users and worked with me to derive out the right set of requirements for the build. They are easy to work with, and follows instructions well. I will hire them again."',
        },
        {
            name: 'Prasanna R.',
            content: '" TheCodingStudio did a fantastic job for my App.Will hire again in future,highly recommended! " ',
            // image: 'https://www.shutterstock.com/image-photo/software-designer-discussing-new-project-600nw-2230165195.jpg',
            // video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4'
        },
        {
            name: 'Daniel',
            content: <ul className='list-disc px-[10px]'>
                <p>"I would definitely recommend TheCodingStudio for the following reasons-</p>
                <li>ROI - You could always find a corporation that is bigger and could deliver the product more proficiently, but the money you pay also goes up significantly. In terms of return on investment, TheCodingStudio provides excellent value.</li>
                <li>Communication/Collaboration - They are an absolute pleasure to work with. Even when things are not going great, they are always courteous and forthcoming.</li>
                <li>Perseverance - They have a strong will to complete a project so even if it takes a lot longer than estimated, they sees the project through."</li>
            </ul>
        }
    ],
    technologyTabs: [
        {
            label: 'ALL',
            key: 'all'
        },
        {
            label: 'FRONTEND',
            key: 'frontend'
        },
        {
            label: 'BACKEND',
            key: 'backend'
        },
        {
            label: 'FULLSTACK',
            key: 'fullstack'
        },
    ],
    galleryImage: [
        {
            key: 1,
            image: '/images/office-images/christmas-group.jpg',
            alt: 'gallery'
        },
        {
            key: 2,
            image: '/images/office-images/womens-day.jpg',
            alt: 'gallery'
        },
        {
            key: 3,
            image: '/images/office-images/work-anniversary.jpg',
            alt: 'gallery'
        },
        {
            key: 4,
            image: '/images/office-images/template.jpeg',
            alt: 'gallery'
        },
        {
            key: 5,
            image: '/images/office-images/cricket.jpg',
            alt: 'gallery'
        },
        {
            key: 6,
            image: '/images/office-images/ganesh.jpg',
            alt: 'gallery'
        },
        {
            key: 7,
            image: '/images/office-images/Movie.jpg',
            alt: 'gallery'
        },
        {
            key: 8,
            image: '/images/office-images/Dinner-1.jpg',
            alt: 'gallery'
        },
    ],
    coreValues: [
        {
            label: 'Innovation',
            description: 'We embrace creativity and strive to stay at the forefront of technological advancements, consistently delivering cutting-edge solutions.',
            src: '/images/adaptability.jpg'
        },
        {
            label: 'Integrity',
            description: 'We uphold the highest standards of honesty and ethical conduct in all our interactions, ensuring trust and transparency with our clients and partners.',
            src: '/images/creativity.jpg'
        },
        {
            label: 'Excellence',
            description: 'We are committed to excellence in every aspect of our work, from the quality of our code to our customer service, aiming to exceed expectations consistently.',
            src: '/images/quality.jpg'
        },
        {
            label: 'Collaboration',
            description: 'We believe in the power of teamwork and foster a collaborative environment where diverse ideas and perspectives are valued and integrated into our solutions.',
            src: '/images/quality.jpg'
        },
        {
            label: 'Customer-Centric',
            description: 'Our clients are at the heart of everything we do. We listen to their needs and work diligently to provide tailored solutions that drive their success.',
            src: '/images/quality.jpg'
        },
        {
            label: 'Continuous Learning',
            description: 'We encourage continuous learning and professional growth, staying updated with the latest industry trends and best practices to maintain our competitive edge.',
            src: '/images/quality.jpg'
        }
    ],
    DeveloperList: [
        {
            label: 'React Js Developer',
            description: 'Skills :- HTML, CSS, JavaScript,  RESTful APIs/GraphQL, Redux  |  1 Year experience  |  Full Time | On-Site'
        },
        {
            label: 'Digital Marketer',
            description: 'Skills :- Digital Marketing Strategy, Content Creation and Copywriting, Email Marketing, Social Media Management, Analytics and Data Interpretation  |  1 Year experience  |  Full Time | On-Site'
        },
        {
            label: 'React Native Developer',
            description: 'Skills :- JavaScript, TypeScript, ClojureScript, REST APIs, Debugging and Testing, Debugging |  1 Year experience  |  Full Time | On-Site'
        },
        {
            label: 'Business Development Executive',
            description: 'Skills :- Sales and Negotiation, CRM, Market Analysis, Communication Skills, Lead Generation, Strategic Planning  |  1 Year experience  |  Full Time | On-Site'
        }
    ],
    ProjectsList: [
        {
            key: 1,
            title: 'Arzooo',
            description: 'Get the technology platform that gives you freedom to manage and grow your business, by eliminating the barriers of scale and capital.',
            projectImage: '/images/project-images/Arzoo .png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/node.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_50px_200px_50px_50px]',
            projectLink: 'https://play.google.com/store/search?q=arzoo+app&c=apps',
            projectLinkIOS: ' https://apps.apple.com/in/app/arzooo-b2b-for-retail-stores/id1497133088',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'React Native',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Node js',
                    serviceImg1: '/svg/angular.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish and Himanshu and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Himanshu ',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }

                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps, the team reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
        {
            key: 2,
            title: 'visuEats',
            description: 'visuEats is a restaurant web and mobile app that provide a photo and video menu displayed powered by eternity solution inclusive of reservation payment processing and delivery features.',
            projectImage: '/images/project-images/Visueats-img.png',
            projectLanguageIcon: ['/svg/react.svg', '/images/firebase-logo.png', '/svg/type-script.svg', '/svg/Google.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_200px_50px_50px_50px]',
            projectLink: 'https://play.google.com/store/apps/details?id=com.visueatsapp',
            projectLinkIOS: ' https://apps.apple.com/no/app/visueats/id1548810661',
            projectResult: 'The app saves your time and lets you lock or unlock your BMW and activate the climate control, anytime and anywhere.Simplified UX helps you find your car in a panic situation. A single tap can find dealers and schedule service appointments.',
            developers: [
                {
                    name: 'Shalini Goswami',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 5,
                    serviceTitle: 'Payment Intergration',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Firebase',
                    serviceImg1: '/images/firebase-logo.png',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'Typescript',
                    serviceImg1: '/svg/type-script.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 4,
                    title: 'React Native',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 5,
                    title: 'Google Developer APIs',
                    serviceImg1: '/svg/Google.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                }

            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu ',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Shalini and Ishan and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Shalini',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Ishan',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Shalini and Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Shalini',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ],
            projectDevelopment: [
                {
                    key: 1,
                    title: 'Admin Panel',
                    Description: 'visuEats Admin is a platform for managing the visuEats service, which likely involves overseeing menu items, restaurant details, and user interactions. It appears to be designed for administrators to control and update the content displayed to users on the visuEats platform.',
                    images: '/images/project-images/VisuEats-Website.png',
                    flex: 'flex-row',
                    borderRadius: '[border-radius:_200px_50px_50px_50px]',
                    websiteLink: 'https://admin.visueats.com/'
                },
                {
                    key: 2,
                    title: 'Webmenu',
                    Description: ' visuEats Web Menu allows users to browse and view menus from various restaurants. It features detailed item descriptions, prices, and possibly images of dishes to enhance the browsing experience. ',
                    images: '/images/project-images/VisuEats-Website.png',
                    flex: 'flex-row-reverse',
                    borderRadius: '[border-radius:_50px_200px_50px_50px]',
                    websiteLink: 'https://webmenu.visueats.com/'
                },
                {
                    key: 3,
                    title: 'App',
                    Description: 'visuEats app allows users to book reservations at restaurants and add orders to their bookings. You can browse menus, select dishes, and confirm your order directly within the app. This convenient feature streamlines the dining experience by combining reservation and ordering functionalities into one platform.',
                    images: '/images/project-images/Visueats-img.png',
                    flex: 'flex-row',
                    borderRadius: '[border-radius:_200px_50px_50px_50px]'
                }
            ]
        },
        {
            key: 3,
            title: 'MyTatva',
            description: 'Personalized Chronic Care Management for a Happy and Healthy Life. MyTatva is a multi-disciplinary chronic care management app, designed to address all aspects of a patient’s health journey.',
            projectImage: '/images/project-images/Tattva-Website.png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/node.svg', '/images/hardware.png'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_50px_200px_50px_50px]',
            projectLink: 'https://play.google.com/store/search?q=mytatva&c=apps',
            projectLinkIOS: 'https://apps.apple.com/in/app/mytatva/id1590299281',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Node JS',
                    serviceImg1: '/svg/node.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'Hardware devices integration',
                    serviceImg1: '/images/hardware.png',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                }
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish, Jignesh, Divyesh, Sweta, Ankit, Gaurang, Saurabh and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Jignesh',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Divyesh',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Sweta',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Ankit',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Gaurang',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Saurabh',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ],
            projectDevelopment: [
                {
                    key: 1,
                    title: 'MyTatva Coach',
                    Description: 'MyTatva Coach Portal is a platform designed for coaches to manage their activities, track progress, and interact with clients. It likely includes tools for scheduling, communication, and performance monitoring, aimed at enhancing the coaching experience. ',
                    images: '/images/project-images/Tattva-Website.png',
                    flex: 'flex-row',
                    borderRadius: '[border-radius:_200px_50px_50px_50px]',
                    websiteLink: 'https://coach.mytatva.in/'
                },
                {
                    key: 2,
                    title: 'App',
                    Description: 'MyTatva offer tools related to wellness, coaching, and personal development. They may include features for tracking health metrics, engaging in wellness programs, and connecting with coaches or mentors. For specific app descriptions and functionalities.',
                    images: '/images/project-images/Tattva.png',
                    flex: 'flex-row-reverse',
                    borderRadius: '[border-radius:_50px_200px_50px_50px]'
                }
            ]
        },
        {
            key: 4,
            title: 'Arzooo Express',
            description: 'Arzooo Express was developed to ease the process of Warehouse managers and workers for procurement and ease of shipping an order from warehouse. Scanning feature in the mobile app enabled the users to scan the products on a faster pace to add and process the items for orders.It helped warehouse managers to keep an active tab on vehicles loaded from their location and items needed to load.',
            projectImage: '/images/project-images/aRZOO express (1).png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/node.svg', '/svg/vue.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_200px_50px_50px_50px]',
            projectLink: 'https://play.google.com/store/apps/details?id=com.arzoooexpress&hl=en_US',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'React Native',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Node js',
                    serviceImg1: '/svg/node.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'vuejs',
                    serviceImg1: '/svg/vue.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                }
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish and Himanshu and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish ',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Himanshu ',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines",
                    }
                },
            ]
        },
        {
            key: 5,
            title: 'Transport EG',
            description: 'Transport EG enabled the digitisation of complete logistics process for companies while moving their goods. From ease of putting the tendor out for selecting the logistics vendor, to tracking the complete shipment journey until it reaches its destination.',
            projectImage: '/images/project-images/Caliper-Website(2).png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/vue.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_50px_200px_50px_50px]',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'vuejs',
                    serviceImg1: '/svg/vue.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Gaurang and Vatsal and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Gaurang',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Vatsal',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Vatsal, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Vatsal',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
        {
            key: 6,
            title: 'GoStor',
            description: 'B2C platform to purchase electronics items at competitive prices.',
            projectImage: '/images/project-images/GoStor.png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/node.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_200px_50px_50px_50px]',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'React Native',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Node JS',
                    serviceImg1: '/svg/node.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu Goswami',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish and Himanshu and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Himanshu',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
        {
            key: 7,
            title: 'Fleischer scholars',
            description: 'The Program provides a mental roadmap, modeled by a 3-legged stool built with the three aforementioned tools, for students to succeed and become master adaptive learners.',
            projectImage: '/images/project-images/Fleischer-scholars-Website.png',
            projectLanguageIcon: ['/svg/react.svg', '/images/go-lang-logo.png', '/svg/vue.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_50px_200px_50px_50px]',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Go Lang',
                    serviceImg1: '/images/go-lang-logo.png',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'vuejs',
                    serviceImg1: '/svg/vue.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish and Himanshu and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Himanshu',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
        {
            key: 8,
            title: 'Spekify',
            description: "The mobile app targeted the restaurant and pubs owners to streamline their drinks menu by creating workspaces for their bar tenders to join and innovate on drink's recipes.",
            projectImage: '/images/project-images/Spekify.png',
            projectLanguageIcon: ['/svg/react.svg', '/svg/angular.svg', '/svg/node.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/images/profile-pic.png'],
            borderRadius: '[border-radius:_50px_200px_50px_50px]',
            projectLinkIOS: 'https://apps.apple.com/in/app/spekify/id6446445849',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'Angular js',
                    serviceImg1: '/svg/angular.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'Node js',
                    serviceImg1: '/svg/node.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                }
            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Tanish and Himanshu and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Tanish',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Himanshu',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            },
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
        {
            key: 9,
            title: 'Tripkliq',
            description: 'Tripkliq is a group travel planning platform that simplifies trip organization. Users can create trips, invite friends, collaborate on itineraries, and manage shared expenses in real-time. It’s ideal for anyone looking to streamline group travel coordination and ensure everyone stays informed and engaged.',
            projectImage: '/images/project-images/Tripkliq-Website(1).png',
            projectLanguageIcon: ['/svg/react.svg', '/images/AI.jpg', '/images/go-lang-logo.png', '/images/openAi.png', '/images/mapbox.png', '/svg/google-maps.svg'],
            images: ['/images/profile-pic.png', '/images/profile-pic.png', '/svg/ceo-image.svg', '/svg/ceo-image.svg'],
            borderRadius: '[border-radius:_200px_50px_50px_50px]',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_left,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                },

                {
                    key: 4,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-left',
                    backgroundServiceGradient: '[background:_linear-gradient(_to_right,_rgba(255,_93,_34,_0.2)_0%,_rgba(255,_93,_34,_0)_100%)]',
                }
            ],
            projectServices: [
                {
                    key: 1,
                    title: 'ReactJS',
                    serviceImg1: '/svg/react.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 2,
                    title: 'AI',
                    serviceImg1: '/images/AI.jpg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 3,
                    title: 'Go-lang',
                    serviceImg1: '/images/go-lang-logo.png',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 4,
                    title: 'OpenAI',
                    serviceImg1: '/images/openAi.png',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                },
                {
                    key: 5,
                    title: 'Mapbox',
                    serviceImg1: '/images/mapbox.png',
                    backgroundGradient: '[background:_linear-gradient(_to_left,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-right',
                },
                {
                    key: 6,
                    title: 'Google maps',
                    serviceImg1: '/svg/google-maps.svg',
                    backgroundGradient: '[background:_linear-gradient(_to_right,_#FFFFFF_0%,rgb(255,255,255,0)_100%)]',
                    animationFading: 'fade-left',
                }

            ],
            projectWorkProcess: [
                {
                    key: 1,
                    imageProject1: "/svg/project-planning.svg",
                    imageProject2: "/svg/project-planning-line.svg",
                    placement: 'left',
                    title: 'Analyze & Planning',
                    background: 'bg-[#FAD1C3]',
                    description: {
                        text: "This project was analyzed and planned by Himanshu, after analyze and planning this project is moved for Frontend.",
                        contributors: [
                            {
                                name: 'Himanshu',
                                highlight: '#FF5D22',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 2,
                    imageProject1: "/svg/project-fronted.svg",
                    imageProject2: "/svg/project-fronted-line.svg",
                    placement: 'right',
                    title: 'Frontend',
                    background: 'bg-[#00AC8D33]',
                    description: {
                        text: "The project was designed and developed by Dilip, Sandeep, Gaurang, Vatsal, Shalini and Bharat and with a keen eye to details. After the frontend phase execution, it moved towards Backend development.",
                        contributors: [
                            {
                                name: 'Dilip',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Sandeep',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Gaurang',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Vatsal',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Shalini',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            },
                            {
                                name: 'Bharat',
                                highlight: '#00AC8D',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 3,
                    imageProject1: "/svg/project-backend.svg",
                    imageProject2: "/svg/project-backend-line.svg",
                    placement: 'left',
                    title: 'Backend',
                    background: 'bg-[#5A49F833]',
                    description: {
                        text: "This project backend was done by Pradip, he have done extremely nice job and we have delivered this before deadline.",
                        contributors: [
                            {
                                name: 'Pradip',
                                highlight: '#5A49F8',
                                fontWeight: 500
                            }
                        ]
                    }
                },
                {
                    key: 4,
                    imageProject1: "/svg/project-testing.svg",
                    imageProject2: "/svg/project-testing-line.svg",
                    placement: 'right',
                    title: 'QA Testing',
                    background: 'bg-[#36BAFE33]',
                    description: {
                        text: "After the above three steps the team, reviewed all the use cases, edge cases, quality parameters for UI and Load testing to make sure the product met the market standards and is bug free!",
                    }
                },
                {
                    key: 5,
                    imageProject1: "/svg/project-launch.svg",
                    placement: 'left',
                    title: 'Launch',
                    background: 'bg-[#F8AE1F1A]',
                    description: {
                        text: "The product launch after aggressive testing happened before set timelines.",
                    }
                },
            ]
        },
    ],

    headerServicesDropdownItems: [
        {
            value: 'all-services',
            label:
                <div className='flex items-center gap-4'>
                    <img className='w-[20px] h-[20px]' src='/images/all-services.png' alt="" />
                    <span className='text-[14px] leading-[18px] font-[400]'>All Services</span>
                </div>
        },
        {
            value: 'frontend',
            label:
                <div className='flex items-center gap-4'>
                    <img className='w-[20px] h-[20px]' src='/svg/frontend-icon.svg' alt="" />
                    <span className='text-[14px] leading-[18px] font-[400]'>Frontend Development</span>
                </div>
        },
        {
            value: 'backend',
            label:
                <div className='flex items-center gap-4'>
                    <img className='w-[20px] h-[20px]' src='/svg/backend-icon.svg' alt="" />
                    <span className='text-[14px] leading-[18px] font-[400]'>Backend Development</span>
                </div>
        },
        {
            value: 'fullstack',
            label:
                <div className='flex items-center gap-4'>
                    <img className='w-[20px] h-[20px]' src='/svg/fullstack-icon.svg' alt="" />
                    <span className='text-[14px] leading-[18px] font-[400]'>Fullstack Development</span>
                </div>
        },
        {
            value: 'digital-solutions',
            label:
                <div className='flex items-center gap-4'>
                    <img className='w-[20px] h-[20px]' src='/images/digital.png' alt="" />
                    <span className='text-[14px] leading-[18px] font-[400]'>Digital Solutions</span>
                </div>
        }
    ],
    galleryTabs: [
        {
            label: 'ALL',
            key: 'all'
        },
        {
            label: 'OFFICE',
            key: 'office'
        },
        {
            label: 'FUN',
            key: 'fun'
        },
        {
            label: 'INFRASTRUCTURE',
            key: 'infrastructure'
        }
    ],
    hubSpotFAQItems: [
        {
            label: 'What Services does TheCodingStudio Offer?',
            key: 1,
            children: <p className='text-[16px] md:text-[19px] md:leading-[35px] font-[400]'>Our company offers a range of IT services, including software development, IT consulting, web design, cloud services, and IT support.</p>
        },
        {
            label: 'What industries do you serve?',
            key: 2,
            children: <p className='text-[16px] md:text-[19px] md:leading-[35px] font-[400]'>We serve a wide range of industries, including healthcare, finance, retail, education, and manufacturing.</p>
        },
        {
            label: 'How does TheCodingStudio Work?',
            key: 3,
            children: <p className='text-[16px] md:text-[19px] md:leading-[35px] font-[400]'>We follow a agile software development approach, which involves close collaboration with our clients and regular iteration and testing. This helps us deliver high-quality software solutions that meet the evolving needs of our clients.</p>
        },
        {
            label: 'Do you offer ongoing support and maintenance ?',
            key: 4,
            children: <p className='text-[16px] md:text-[19px] md:leading-[35px] font-[400]'>Yes, we offer ongoing support and maintenance services to ensure that our software solutions continue to perform at their best and meet the changing needs of our clients.</p>
        }
    ],
    teamMembers: [

        {
            name: 'Rishabh Goswami',
            role: 'Chief Management Officer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Riya Vani',
            role: 'Human Resources',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Shalini Goswami',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Ankit Lathiya',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },

        {
            name: 'Pradip Vadachak',
            role: 'Fullstack Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Tanish Jain',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Jignesh kubavat',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Divyesh Godhani',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Abhishek Paul',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Sweta Pandey',
            role: 'Fronted Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Inderjeet Kaur',
            role: 'Backend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Saurabh Warge',
            role: 'Backend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Mihir Dhore',
            role: 'Salesforce Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Gaurav Lokhande',
            role: 'Salesforce Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Parth Dhimmar',
            role: 'Backend & AI-ML Developer',
            image: '/images/profile-pic.png'
        }
    ],
    teamLeadProfile: [
        {
            key: 1,
            first_name: 'Himanshu',
            last_name: 'Goswami',
            position: 'CEO & Founder',
            description: 'Himanshu is a CEO of TheCodingStudio. He is working on Fullstack Technologys like ReactJS, Laravel, VueJS and He is expert in HubSpot CRM and Backend stuff also He have good knowledge about the Bootstrap, Tailwind, Webflow, AlpineJS, Shopify.',
            profileImg: '/images/profile-pic.png',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
    ],
    teamMemberProfile: [
        {
            key: 1,
            name: 'Rishabh Goswami',
            position: 'CMO',
            description: 'Rishabh is a Cmo of TheCodingStudio. He is working on Fullstack Technologys like ReactJS, Laravel, VueJS and He is expert in HubSpot CRM and Backend stuff also He have good knowledge about the Bootstrap, Tailwind, Webflow, AlpineJS, Shopify.',
            profileImg: '/images/profile-pic.png',
            role: 'CMO',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 2,
            name: 'Riya Vani',
            position: 'Human Resources',
            description: 'Riya is a HR in the TheCodingStudio. She is working on Fullstack Technologys like ReactJS, Laravel, VueJS and He is expert in HubSpot CRM and Backend stuff also He have good knowledge about the Bootstrap, Tailwind, Webflow, AlpineJS, Shopify.',
            profileImg: '/images/profile-pic.png',
            role: 'Human Resources',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 3,
            name: 'Shalini Goswami',
            position: 'Frontend Developer',
            description: 'Shalini is working on Frontend Technologys and she is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 4,
            name: 'Ankit Lathiya',
            position: 'Frontend Developer',
            description: 'Ankit is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also he has good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 5,
            name: 'Pradip Vadachak',
            position: 'Fullstack developer',
            description: 'Pradip is working on Fullstack Technologys and he is expert in HubSpot CRM and Fullstack stuff also he has good knowledge about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Fullstack Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 6,
            name: 'Tanish Jain',
            position: 'Frontend Developer',
            description: 'Tanish is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 7,
            name: 'Jignesh kubavat',
            position: 'Frontend Developer',
            description: 'Jignesh is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 8,
            name: 'Divyesh Godhani',
            position: 'Fronted Developer',
            description: 'Divyesh is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Fronted Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 9,
            name: 'Abhishek Paul',
            position: 'Fronted Developer',
            description: 'Abhishek is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Fronted Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 10,
            name: 'Sweta Pandey',
            position: 'Fronted Developer',
            description: 'Sweta is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Fronted Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 11,
            name: 'Inderjeet Kaur',
            position: 'Backend Developer',
            description: 'Inderjeet is working on Frontend Technologys and she is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Backend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 12,
            name: 'Saurabh Warge',
            position: 'Frontend Developer',
            description: 'Saurabh is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 13,
            name: 'Mihir Dhore',
            position: 'Salesforce Developer',
            description: 'Mihir is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Salesforce Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 14,
            name: 'Gaurav Lokhande',
            position: 'Salesforce Developer',
            description: 'Gaurav is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Salesforce Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 15,
            name: 'Parth Dhimmar',
            position: 'Backend & AI-ML developer',
            description: 'Parth is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Backend & AI-ML developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
    ],
    alumanis: [
        {
            name: 'Vatsal Patel',
            role: 'Fullstack Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Hemlata Chaudhary',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Gaurang Patel',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Ishan Gohil',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        },
        {
            name: 'Bharat Maliya',
            role: 'Frontend Developer',
            image: '/images/profile-pic.png'
        }
    ],
    teamAlumanisProfile: [
        {
            key: 1,
            name: 'Vatsal Patel',
            position: 'Fullstack Developer',
            description: 'Vatsal is working on Fullstack Technologys and he is expert in HubSpot CRM and Fullstack stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Fullstack Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 2,
            name: 'Hemlata Chaudhary',
            position: 'Frontend Developer',
            description: 'Hemlata is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 3,
            name: 'Gaurang Patel',
            position: 'Frontend Developer',
            description: 'Gaurang is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 4,
            name: 'Ishan Gohil',
            position: 'Frontend Developer',
            description: 'Ishan is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },
        {
            key: 5,
            name: 'Bharat Maliya',
            position: 'Frontend Developer',
            description: 'Bharat is working on Frontend Technologys and he is expert in HubSpot CRM and Frontend stuff also she have good knowledge  about the Shopify and Wordpress.',
            profileImg: '/images/profile-pic.png',
            role: 'Frontend Developer',
            expertise: [
                {
                    key: 1,
                    techImg: '/svg/react.svg'
                },
                {
                    key: 2,
                    techImg: '/svg/node.svg'
                },
                {
                    key: 3,
                    techImg: '/svg/php.svg'
                },
            ]
        },

    ],
    languageServices: [
        {
            key: 1,
            title: 'ReactJS',
            description: 'ReactJS developer assures quality applications with the correct interfaces. This is a wonderful JavaScript framework loved by the developers. Most of the small, medium sized and enterprise companies are leveraging ReactJS development.',
            serviceImg1: '/svg/react.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 5,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                }
            ]
        },
        {
            key: 2,
            title: 'AngularJS',
            description: 'We provide the AngularJS web development exactly as listed in your requirements. With our deep domain expertise, we can help you develop a wide range of applications and many other integrations of AngularJS framework.',
            serviceImg1: '/svg/angular.svg',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'frontend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Ravi Chodvadiya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 5,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                }
            ]

        },
        {
            key: 3,
            title: 'React Native',
            description: 'We deliver top-tier mobile apps with intuitive interfaces using React Native—a beloved framework among developers. From startups to enterprises, businesses of all sizes trust our expertise to create cross-platform applications that excel in user engagement and adaptability.',
            serviceImg1: '/svg/react.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 4,
            title: 'HTML & CSS',
            description: ' We are a reputable web development company in Surat, and wit a combination of JavaScript and the latest W3C standards with CSS3, we create absolutely robust and gorgeous websites.',
            serviceImg1: '/svg/html.svg',
            serviceImg2: '/svg/css.svg',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'frontend',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 5,
            title: 'Swift',
            description: 'Swift is a powerful and intuitive programming language for all Apple platforms. It’s easy to get started using Swift, with a concise-yet-expressive syntax and modern features you’ll love. Swift code is safe by design and produces software that runs lightning‑fast.',
            serviceImg1: '/images/swift-og.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]
        },
        {
            key: 6,
            title: 'Java',
            description: ' Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.',
            serviceImg1: '/images/java.jpeg',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'frontend',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 7,
            title: 'Kotlin',
            description: "Our Kotlin experts excel in crafting modern, efficient applications with superior code maintainability and scalability, leveraging the language's concise syntax and seamless interoperability with Java.",
            serviceImg1: '/images/Kotlin_Icon.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 8,
            title: 'Flutter',
            description: "Our Flutter experts create cross-platform apps with stunning interfaces and seamless performance, ensuring exceptional user experiences across devices.",
            serviceImg1: '/images/flutter.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'frontend',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 9,
            title: 'VueJS',
            description: 'We offer a range of VueJS services to help you harness the full potential of this framework and elevate your web applications to new heights.',
            serviceImg1: '/svg/vue.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            language1: "fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 10,
            title: 'NodeJS',
            description: 'Our adept team of NodeJS developers meticulously crafts powerful APIs, integrates databases, and engineers server-side logic to ensure your applications run seamlessly ',
            serviceImg1: '/svg/node.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'backend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Higher Performance',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 5,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                }
            ]
        },

        {
            key: 11,
            title: 'MongoDB',
            description: 'The performance of MongoDB is 100 times quicker than any other relational databases. MongoDB is created on a scale-out architecture which allows the developers to create applications with the help of agile methodology.',
            serviceImg1: '/svg/mongo-db.svg',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'backend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 12,
            title: 'MySQL',
            description: 'Find the forefront of efficient data management with our exclusive MySQL services. From designing optimized database schemas to implementing complex queries and optimizations, we ensure that your applications handle data...',
            serviceImg1: '/svg/mysql.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'backend',
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 13,
            title: 'Redis',
            description: 'Redis allows Niantic to use less overhead to balance their server load and offer great player experiences.',
            serviceImg1: '/images/redis-logo.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'backend',
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 14,
            title: 'Golang',
            description: 'Go is an open source programming language that makes it simple to build secure, scalable systems.',
            serviceImg1: '/images/go-lang-logo.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'backend',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Dipali Gediya',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'Guarantees Stable Code',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 4,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
            ]

        },
        {
            key: 16,
            title: 'Firebase',
            description: 'Firebase helps you make your app the best it can be with tools for the entire journey. Backed by Google. Monitor App Performance. Cross-Platform Solutions. Accelerate Development.',
            serviceImg1: '/images/firebase-logo.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'backend',
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 17,
            title: 'SalesForce',
            description: "Personalise every experience along the customer journey with the Customer 360. Unify marketing, sales, service, commerce, and IT on the world's #1 CRM.",
            serviceImg1: '/images/salesforce-logo.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'fullstack',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 18,
            title: 'AI/ML',
            description: 'Artificial intelligence (AI) and machine learning (ML) are used interchangeably, but they differ with uses, data sets, and more.',
            serviceImg1: '/images/ai-ml.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'fullstack',
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 19,
            title: 'GenAI',
            description: 'Generative AI (GenAI) is a type of Artificial Intelligence that can create a wide variety of data, such as images, videos, audio, text, and 3D models.',
            serviceImg1: '/images/genAi-logo.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'fullstack',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 20,
            title: 'DevOps',
            description: 'DevOps is a combination of software development (dev) and operations (ops). It is defined as a software engineering methodology which aims to integrate the work of development teams and operations teams by facilitating a culture of collaboration and shared responsibility.',
            serviceImg1: '/images/devops-logo.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'fullstack',
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 21,
            title: 'Web3',
            description: 'Web3 is an idea for a new iteration of the World Wide Web which incorporates concepts such as decentralization, blockchain technologies, and token-based economics.',
            serviceImg1: '/images/web3-logo.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'fullstack',
            // language1:"fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 22,
            title: 'BlockChain',
            description: 'A blockchain is a digitally distributed, decentralized, public ledger that exists across a network.',
            serviceImg1: '/images/blockchain-logo.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'fullstack',
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 23,
            title: 'Next.Js',
            description: 'Built on a foundation of fast, production-grade tooling ... React The library for web and native user interfaces.',
            serviceImg1: '/svg/next-js.svg',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'frontend',
            language1: "fullstack",
            developers: [
                {
                    name: 'Deep Bhimani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-right'
                },
                {
                    name: 'Puja Varsani',
                    image: '/images/profile-pic.png',
                    animation: 'fade-left'
                },
            ],
            langServices: [
                {
                    key: 1,
                    serviceTitle: 'SEO Friendly',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
                {
                    key: 2,
                    serviceTitle: 'Quick Speed',
                    animation: 'fade-left',
                    backgroundServiceGradient: 'to_right',
                },
                {
                    key: 3,
                    serviceTitle: 'Faster Rendering',
                    animation: 'fade-right',
                    backgroundServiceGradient: 'to_left',
                },
            ]

        },
        {
            key: 24,
            title: 'Strategy and Planning',
            description: 'ReactJS developer assures quality applications with the correct interfaces. This is a wonderful JavaScript framework loved by the developers. Most of the small, medium sized and enterprise companies are leveraging ReactJS development.',
            serviceImg1: '/images/strategy-planning.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'digital',
        },
        {
            key: 25,
            title: 'Website Development Consulting',
            description: "Website Development Consulting provides expert advice to create, improve, and maintain websites, focusing on design, functionality, user experience, SEO, and digital marketing to meet client goals and engage their target audience.",
            serviceImg1: '/images/website-development.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'digital',
        },
        {
            key: 26,
            title: 'Mobile App Development Consulting',
            description: 'Mobile App Development Consulting offers expert advice to design, develop, and maintain mobile applications, focusing on functionality, user experience, performance, and market strategies to meet client needs and engage users effectively.',
            serviceImg1: '/images/mobile-app-development.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'digital',
        },
        {
            key: 27,
            title: 'Ecommerce product Development consulting',
            description: 'Ecommerce Product Development Consulting provides expert guidance in creating, optimizing, and maintaining online stores, focusing on product management, user experience, payment systems, and marketing strategies to boost sales and customer engagement.',
            serviceImg1: '/images/ecommerce.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'digital',
        },
        {
            key: 28,
            title: 'HealthCare Product Development consulting',
            description: 'Healthcare Product Development Consulting offers expert guidance in creating, improving, and maintaining healthcare products, focusing on innovation, compliance, user experience, and market strategies to ensure effective solutions and patient care.',
            serviceImg1: '/images/healthcare.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'digital',
        },
        {
            key: 29,
            title: 'Prop Product Development consulting',
            description: 'Prop Product Development Consulting provides expert advice on designing, creating, and optimizing props for various industries, focusing on innovation, functionality, aesthetics, and market strategies to meet client needs and enhance user experience.',
            serviceImg1: '/images/prop-product.png',
            backgroundGradient: 'to_right',
            animationFading: 'fade-left',
            language: 'digital',
        },
        {
            key: 30,
            title: 'B2B and B2C product Development consulting',
            description: "B2B and B2C Product Development Consulting offers specialized guidance in designing, developing, and optimizing products for both business-to-business (B2B) and business-to-consumer (B2C) markets. Consultants focus on innovation, market fit, user experience, and tailored strategies to effectively serve client objectives and engage target audiences.",
            serviceImg1: '/images/b2c-develop.png',
            // serviceImg2: '/images/b2c-develop.png',
            backgroundGradient: 'to_left',
            animationFading: 'fade-right',
            language: 'digital',
        },
    ],

    CareerBenefit: [
        {
            key: 1,
            image: '/svg/learning.svg',
            title: 'Advance Learning',
            description: 'Access continuous learning opportunities to enhance your skills and knowledge..'
        },
        {
            key: 2,
            image: '/svg/snacks-icon.svg',
            title: 'Snacks',
            description: 'Have healthy snack or drinks on site.',
            marginTop: "mt-[0%] md:mt-[6.5%] "
        },
        {
            key: 3,
            image: '/svg/flexibility.svg',
            title: 'Flexibility',
            description: ' Flexible work hours and supportive a work-life balance.'
        },
        {
            key: 4,
            image: '/svg/payment-icon.svg',
            title: 'Paid Leaves',
            description: "We provide 12 paid leaves in a year.",
            marginTop: "mt-[0%] md:mt-[6.5%] "
        },
        {
            key: 5,
            image: '/svg/activity-icon.svg',
            title: 'Fun and Activities',
            description: 'Board games, team outings, company parties, and more'
        },
        {
            key: 6,
            image: '/svg/activity-icon.svg',
            title: 'Working Days',
            description: '5 Days working',
            marginTop: "mt-[0%] md:mt-[6.5%] "
        },
    ],
    photosAll: [
        {
            key: 1,
            image: '/images/office-images/cake.jpg'
        },

        {
            key: 2,
            image: '/images/office-images/group-photo.jpg'
        },
        {
            key: 3,
            image: '/images/office-images/womens-day.jpg'
        },
        {
            key: 4,
            image: '/images/office-images/family-ganesh.jpg'
        },
        {
            key: 5,
            image: '/images/office-images/work-anniversary.jpg'
        },
        {
            key: 6,
            image: '/images/office-images/template.jpeg'
        },
        {
            key: 7,
            image: '/images/office-images/independance-day1.jpg'
        },
        {
            key: 8,
            image: '/images/office-images/OFFICE.jpg'
        },
        {
            key: 10,
            image: '/images/office-images/template1.jpeg'
        },
        {
            key: 11,
            image: '/images/office-images/birthday.jpg'
        },

        {
            key: 12,
            image: '/images/office-images/inaugration-group.jpg'
        },
        {
            key: 13,
            image: '/images/office-images/Boys.jpg'
        },
        {
            key: 14,
            image: '/images/office-images/christmas.jpg'
        },
        {
            key: 15,
            image: '/images/office-images/Celebration.jpg'
        },

        {
            key: 16,
            image: '/images/office-images/cricket.jpg'
        },
        {
            key: 17,
            image: '/images/office-images/christmas-group.jpg'
        },
        {
            key: 18,
            image: '/images/office-images/Dinner-1.jpg'
        },
        {
            key: 19,
            image: '/images/office-images/ganesh.jpg'
        },
        {
            key: 20,
            image: '/images/office-images/halloween.jpg'
        },
        {
            key: 21,
            image: '/images/office-images/cricket1.jpg'
        },
        {
            key: 22,
            image: '/images/office-images/inaugration.jpg'
        },
        {
            key: 23,
            image: '/images/office-images/Movie.jpg'
        },
        {
            key: 24,
            image: '/images/office-images/ganesh1.jpg'
        },
        {
            key: 25,
            image: '/images/office-images/Nasta.jpg'
        },
        {
            key: 26,
            image: '/images/office-images/plant.jpg'
        },
        {
            key: 27,
            image: '/images/office-images/christmas.jpeg'
        },
        {
            key: 28,
            image: '/images/office-images/diwali-gift.jpg'
        },
        {
            key: 29,
            image: '/images/office-images/colour-cracker.JPG'
        },
        {
            key: 30,
            image: '/images/office-images/christmas-winner.jpg'
        },
        {
            key: 31,
            image: '/images/office-images/ganesh-short.JPG'
        },
        {
            key: 32,
            image: '/images/office-images/fun-nasta.jpg'
        },
        {
            key: 33,
            image: '/images/office-images/birthday-group.jpg'
        },
        {
            key: 34,
            image: '/images/office-images/ganesh-under.JPG'
        },
        {
            key: 35,
            image: '/images/office-images/independance-day.jpeg'
        },
        {
            key: 36,
            image: '/images/office-images/Dinner.jpg'
        },
    ],
    photosFun: [
        {
            key: 1,
            image: '/images/office-images/Boys.jpg'
        },
        {
            key: 2,
            image: '/images/office-images/christmas.jpg'
        }, {
            key: 3,
            image: '/images/office-images/halloween.jpg'
        },
        {
            key: 4,
            image: '/images/office-images/Celebration.jpg'
        },
        {
            key: 5,
            image: '/images/office-images/christmas-winner.jpg'
        },
        {
            key: 6,
            image: '/images/office-images/cricket.jpg'
        }, {
            key: 7,
            image: '/images/office-images/ganesh.jpg'
        },
        {
            key: 8,
            image: '/images/office-images/Dinner-1.jpg'
        },
        {
            key: 9,
            image: '/images/office-images/christmas-group.jpg'
        },
        {
            key: 10,
            image: '/images/office-images/work-anniversary.jpg'
        },
        {
            key: 11,
            image: '/images/office-images/Fun-selfie.jpg'
        },
        {
            key: 12,
            image: '/images/office-images/cricket1.jpg'
        },
        {
            key: 13,
            image: '/images/office-images/ganesh1.jpg'
        },
        {
            key: 14,
            image: '/images/office-images/inaugration.jpg'
        },
        {
            key: 15,
            image: '/images/office-images/independance-day1.jpg'
        },
        {
            key: 16,
            image: '/images/office-images/Movie.jpg'
        },
        {
            key: 17,
            image: '/images/office-images/Nasta.jpg'
        },
        {
            key: 18,
            image: '/images/office-images/plant.jpg'
        },
        {
            key: 19,
            image: '/images/office-images/christmas.jpeg'
        },
        {
            key: 20,
            image: '/images/office-images/Dinner.jpg'
        },
        {
            key: 21,
            image: '/images/office-images/independance-day.jpeg'
        },
    ],
    photosOffice: [
        {
            key: 1,
            image: '/images/office-images/womens-day.jpg'
        },
        {
            key: 2,
            image: '/images/office-images/group-photo.jpg'
        },
        {
            key: 3,
            image: '/images/office-images/template.jpeg'
        },
        {
            key: 4,
            image: '/images/office-images/Boys.jpg'
        },
        {
            key: 5,
            image: '/images/office-images/christmas.jpg'
        },
        {
            key: 6,
            image: '/images/office-images/template1.jpeg'
        },
        {
            key: 7,
            image: '/images/office-images/plant.jpg'
        },
        {
            key: 8,
            image: '/images/office-images/fun-nasta.jpg'
        },
    ],
    photosInfrastructure: [
        {
            key: 1,
            image: '/images/office-images/OFFICE.jpg'
        },
    ]
};

export default commonConstants;

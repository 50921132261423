import { Tooltip } from 'antd';
import React from 'react'
import Slider from 'react-slick';
import commonConstants from '../../../utils/constants'
import UnderLineText from '../../atoms/UnderlineText';
import './style.scss'

const CoreValues = () => {
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img className='absolute md:w-auto w-[8%] right-0 top-[-64px] md:top-[-80px] cursor-pointer' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-next.svg' : '/svg/enabled-next.svg'} alt='next-arrow' />
        );
    }

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img className='absolute md:w-auto w-[8%] right-[60px] md:right-[80px] top-[-64px] md:top-[-80px] cursor-pointer' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-previous.svg' : '/svg/enabled-previous.svg'} alt='prev-arrow' />
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 728, //'md' breakpoint
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1024, // 'lg' breakpoint
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    return (
        <div className='flex flex-col gap-[30px] mt-[100px] relative'>
            <UnderLineText text={'Core Values'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
            <span data-aos="fade-up" className='sub-title'>
                TheCodingStudio core values
            </span>
            <Slider {...settings}>
                {
                    commonConstants.coreValues.map((value, idx) => {
                        return (
                            <div data-aos="fade-up" className={`pr-[90px] pb-[40px]`} key={idx}>
                                <div className='bg-white p-[30px] pb-[100px] relative rounded-[30px] flex flex-col gap-5'>
                                    <div className='border-container left-5 absolute w-full h-full rounded-[30px] z-[-1]'>
                                        <div className='border-[2.5px] w-full h-full border-theme-core rounded-[30px]' />
                                    </div>
                                    <img className='w-[60%]' src={value.src} alt='' />
                                    <span className='font-[400] text-[30px] leading-[38px]'>
                                        {value.label}
                                    </span>
                                    <Tooltip title={value.description}>
                                        <span className='font-[400] text-[22px] leading-[28px] text-[#6B6B6B] line-clamp-[8]'>
                                            {value.description}
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default CoreValues
import React from 'react'
import LanguageDescription from '../components/organisms/LanguageScreen/LanguageDescription/LanguageDescription'
import ProjectStats from '../components/molecules/ProjectStats'
import HubspotWorkProcess from '../components/organisms/HubspotWorkProcess'
import Testimonials from '../components/organisms/Testimonials'
import FAQs from '../components/organisms/FAQs'
import commonConstants from '../utils/constants'
import ContactUs from '../components/molecules/ContactUs'
import ProfilePortfolio from '../components/organisms/ProfilePortfolio/ProfilePortfolio'
import LinkServices from '../components/molecules/LinkServices/LinkServices'
import { useLocation } from 'react-router-dom'
import LanguageServices from '../components/organisms/LanguageScreen/LanguageServices/LanguageServices'
import Branding from '../components/molecules/Branding/Branding'

const LanguagePage = () => {
    const { state } = useLocation();
    return (
        <div className='text-black px-2 lg:px-[100px] relative py-2 lg:py-[60px] relative'>
            <div className='relative flex flex-col items-center bg-[center] [background-size:100%] bg-gallery bg-no-repeat w-[100%] relative z-[1]'>
                <div className='absolute right-[-39%] md:right-[4%] top-[20%]'>
                    <img data-aos="fade-right" src='/svg/computer-boy.svg' alt='' className='w-[35%] lg:w-auto' />
                </div>
                <div className='absolute top-[0px] left-[0%] '>
                    <img data-aos="fade-right" src='/svg/space-jet.svg' alt='' className='w-[35%] lg:w-auto' />
                </div>
                <div className='flex flex-col justify-center items-center gap-8 relative'>
                    <img data-aos="fade-down" src={state?.serviceImg1} className='w-[80px] h-[80px] lg:w-[226px] lg:h-[200px] lg:bottom-[250px]' alt='' />
                    <div className='flex flex-col items-center'>
                        <div className=' text-[45px] md:text-[68px] font-[700] leading-[100px] flex flex-col items-center justify-center '>
                            <div data-aos="fade-up">{state.title} is to be</div>
                            <img data-aos="fade-right" className='mt-[-25px]' src='/svg/language-text.svg' alt='' />
                        </div>
                        <p data-aos="zoom-in" className='text-[9vw] font-[700] tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000_-27%,_#fff_79%)] uppercase font-sans'>
                            {state.title}
                        </p>
                    </div>
                </div>

            </div>
            <LinkServices service={state} />
            <LanguageDescription service={state} />
            <LanguageServices service={state} titleDes={`Our ${state?.title} Services`} title={'Services we Using'} />
            <ProjectStats />
            <Branding />
            <HubspotWorkProcess service={state}/>
            <ProfilePortfolio title={'Our Recent Work'} />
            <Testimonials />
            {/* <LanguageTeam service={state} /> */}
            <FAQs faqItems={commonConstants.hubSpotFAQItems} />
            <ContactUs />
        </div>
    )
}

export default LanguagePage

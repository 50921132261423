import React from 'react'

const TermsCondition = () => {
  return (
    <div className='pt-[3%] pl-[5%] pr-[2%]'>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 1. Acceptance of Terms</span> </div>
      <div className='mb-[15px]'>
        By accessing and using  <a href='https://thecodingstudio.in/' className='text-[--themeColor]'>thecodingstudio.in</a> ("Website"),
        you agree to comply with and be bound by the following terms and conditions ("Terms").
        If you do not agree to these Terms, please do not use our Website.
      </div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 2. Use of the Website </span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Eligibility:</span>  You must be at least 18 years old or have reached the age of majority in your jurisdiction to use this Website.
          By using this Website, you represent and warrant that you meet these eligibility requirements.</li>
        <li><span className='font-semibold'>License to Use:</span> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our Website for personal and commercial purposes, subject to these Terms.</li>
        <li><span className='font-semibold'>Prohibited Conduct:</span> You agree not to engage in any of the following activities: </li>
        <ul className='mb-[15px] flex flex-col gap-[10px] list-disc'>
          <li>Using the Website for any illegal or unauthorized purpose.</li>
          <li>Posting or transmitting any content that is unlawful, harmful, threatening, abusive, defamatory, vulgar, obscene, or otherwise objectionable.</li>
          <li>Attempting to interfere with or disrupt the operation of the Website, its servers, or networks connected to it.</li>
          <li>Using any automated system, including "robots," "spiders," or "offline readers," to access the Website in a manner that sends more request messages to our servers than a human could reasonably produce in the same period of time.
          </li>
        </ul>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 3. User Accounts</span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Account Creation:</span> To access certain features of the Website, you may be required to create an account. You agree to provide accurate, complete, and up-to-date information when creating your account.</li>
        <li><span className='font-semibold'>Account Security:</span> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          You agree to notify us immediately of any unauthorized use of your account.</li>
        <li><span className='font-semibold'>Account Termination:</span>We reserve the right to terminate your account at our sole discretion if we believe you have violated these Terms or engaged in fraudulent or harmful activities.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 4. Intellectual Property</span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Ownership: </span> All content, materials, and intellectual property on the Website, including but not limited to text,
          graphics, logos, images, and software, are the property of thecodingstudio
          or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
        <li><span className='font-semibold'>Limited Use:</span>You may not copy, reproduce, distribute, or create derivative works of any content from the Website without our prior written consent.</li>
        <li><span className='font-semibold'>Trademarks:</span>All trademarks, service marks, and logos used on the Website are the trademarks of thecodingstudio or their respective owners.
          You may not use any trademarks without the prior written consent of the owner.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 5. Products and Services</span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Availability: </span>All products and services offered on the Website are subject to availability and may be withdrawn or modified at any time without notice.</li>
        <li><span className='font-semibold'>Pricing: </span>We reserve the right to change the prices of our products and services at any time.
          Prices for products are listed in [Currency] and include applicable taxes unless otherwise stated.</li>
        <li><span className='font-semibold'>Orders: </span> By placing an order on the Website, you agree to pay the total amount for the order, including any applicable taxes and shipping fees.
          We reserve the right to refuse or cancel any order at our discretion.</li>
      </ul>


      <div className='mb-[15px]'><span className='font-bold text-7px]'> 6. Payment Terms</span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>Payment Methods: </span>We accept various payment methods, including [list accepted payment methods, e.g., credit cards, PayPal, etc.].
          Payments are processed through secure third-party payment processors.</li>
        <li><span className='font-semibold'>Billing Information: </span>You agree to provide accurate and complete billing information. If we cannot process your payment, we may cancel your order.</li>
        <li><span className='font-semibold'>Refunds and Returns: </span> Please refer to our [Refund and Return Policy] for detailed information on refunds and returns.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 7. Third-Party Links</span> </div>
      <div className='mb-[15px]'> The Website may contain links to third-party websites or services that are not owned or controlled by thecodingstudio. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
        We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 8.  Disclaimers and Limitation of Liability</span> </div>
      <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
        <li><span className='font-semibold'>No Warranties: </span>The Website is provided on an “as is” and “as available” basis without any warranties, express or implied.
          We do not warrant that the Website will be uninterrupted, error-free, or secure.</li>
        <li><span className='font-semibold'>Limitation of Liability: </span>To the fullest extent permitted by law, thecodingstudio and its affiliates, directors, employees,
          and agents will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Website,
          including but not limited to damages for loss of profits, data, or goodwill.</li>
      </ul>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 9. Indemnification</span> </div>
      <div className='mb-[15px]'> You agree to indemnify, defend, and hold harmless thecodingstudio and its affiliates, directors,
        employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney’s fees, arising out of
        or in any way connected with your use of the Website, your violation of these Terms, or your violation of any rights of another.</div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 10. Governing Law</span> </div>
      <div className='mb-[15px]'>These Terms and your use of the Website will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles.
        You agree to submit to the exclusive jurisdiction of the courts located in [Your Jurisdiction] for the resolution of any disputes.</div>

      <div className='mb-[15px]'><span className='font-bold text-7px]'> 11. Changes to These Terms</span> </div>
      <div className='mb-[15px]'>We may update these Terms from time to time to reflect changes in our practices or legal requirements. The updated Terms will be posted on
        this page with the “Effective Date” updated accordingly. We encourage you to review these Terms periodically.</div>


      <div className='mb-[15px]'><span className='font-bold text-7px]'> 12. Contact Us </span> </div>
      <div className='mb-[15px]'>If you have any questions or concerns about these Terms, please contact us at:</div>
      <ul className='mb-[55px] flex flex-col gap-[10px] list-disc'>
        <li><span className='font-semibold'>Email: </span>
          <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`mailto:hr@thecodingstudio.in`}>
            hr@thecodingstudio.in
          </a></li>
        <li><span className='font-semibold'>Phone: </span>
          <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:+917990345580`}>
            +917990345580
          </a></li>
        <li><span className='font-semibold'>Address: </span>  436-437,The Boulevard, Near Pratham Circle, Gallaria Street Green city road , pal , Adajan, Surat, Gujarat 395009</li>
      </ul>

    </div>
  )
}

export default TermsCondition
import React, { useEffect, useState } from 'react';
import { PureIncrement } from 'pure_counter';

const ProjectStats = () => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setKey(prevKey => prevKey + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div data-aos="fade-up" className={`relative mt-[100px] bg-project-stats bg-no-repeat bg-cover w-full rounded-[60px] flex justify-evenly items-center gap-[4rem] md:gap-5 flex-wrap p-[40px]`}>
            <div className='absolute h-full w-full bg-[#000000B2] rounded-[60px] z-[-1]' />
            <div className='flex flex-col gap-0 items-center'>
                <img src='/svg/happy-clients.svg' alt='' />
                <div className='mt-auto md:mt-8 text-[#00B2FF] text-[45px] font-[Fredoka]  leading-[57px] font-[600]'>
                    <PureIncrement key={key} start={1} end={26} duration={2} className='' />
                    <span className=''>+</span>
                </div>
                <span className='text-white text-[21px] leading-[26px] font-[500]'>Happy Clients</span>
            </div>
            <div className='flex flex-col gap-0 items-center'>
                <img src='/svg/project-completed.svg' alt='' />
                <div className='mt-auto md:mt-8 text-[#FF6B00] text-[45px] font-[Fredoka]  leading-[57px] font-[600]'>
                    <PureIncrement key={key} start={1} end={50} duration={2} className='' />
                    <span className=''>+</span>
                </div>
                <span className='text-white text-[21px] leading-[26px] font-[500]'>Project Completed</span>
            </div>
            <div className='flex flex-col gap-0 items-center'>
                <img src='/svg/hours-of-work.svg' alt='' />
                <div className='mt-auto md:mt-8 text-[#12E197] text-[45px] font-[Fredoka]  leading-[57px] font-[600]'>
                    <PureIncrement key={key} start={1} end={220} duration={2} className='' />
                    <span className=''>+</span>
                </div>
                <span className='text-white text-[21px] leading-[26px] font-[500]'>Hours of Work</span>
            </div>
            <div className='flex flex-col gap-0 items-center'>
                <img src='/svg/repeat-business.svg' alt='' />
                {/* <span className='text-[#F8AE1F] text-[45px] font-[Fredoka] mt-8 leading-[57px] font-[600]'>85%</span> */}
                <div className='mt-auto md:mt-8 text-[#F8AE1F] text-[45px] font-[Fredoka]  leading-[57px] font-[600]'>
                    <PureIncrement key={key} start={1} end={85} duration={2} className='' />
                    <span className=''>+</span>
                </div>
                <span className='text-white text-[21px] leading-[26px] font-[500]'>Repeat Business</span>
            </div>
        </div>
    )
}

export default ProjectStats
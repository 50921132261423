import React from 'react'
import LinkServices from '../components/molecules/LinkServices/LinkServices'
import LanguageDescription from '../components/organisms/LanguageScreen/LanguageDescription/LanguageDescription'
import LanguageServices from '../components/organisms/LanguageScreen/LanguageServices/LanguageServices'
import ProjectServices from '../components/organisms/ProjectScreens/ProjectServices/ProjectServices'
import JoinUsForm from '../components/molecules/JoinUsForm'
import ContactUs from '../components/molecules/ContactUs'
import ProjectTestimonials from '../components/organisms/ProjectScreens/ProjectTestimonials/ProjectTestimonials'
import ProjectProcess from '../components/organisms/ProjectScreens/ProjectProcess/ProjectProcess'
import { useLocation } from 'react-router-dom'
import ProjectDescription from '../components/organisms/ProjectDescription/ProjectDescription'
import ProjectResult from '../components/molecules/ProjectResult/ProjectResult'

const ProjectDetailsPage = () => {
  const { state } = useLocation();
  return (
    <div>
      <div className='text-black px-2 md:px-[70px] relative py-[30px]'>
        {/* {state?.projectsDetails?.map((project) => {
          console.log(project)
          return ( */}
        <div>
          {/* <div
            data-aos="fade-up" className="w-[100%] h-[635px]"
            style={{
              backgroundImage: `linear-gradient(to right bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${state.projectImage})`
            }}
          ></div> */}
          <div className='relative w-[100%] h-[635px]'>
            <div className='absolute inset-0 bg-black opacity-50  w-[100%] h-[635px]'></div>
            <img src={state.projectImage} alt='carousel' className=' w-[100%] h-[635px] object-none' />
          </div>
          <LinkServices service={state} />
          <div className='flex gap-8 justify-center'>
            {state?.projectLink && <a href={state?.projectLink} target='_blank' className='bg-[#FF5D22] px-8 py-4 text-white font-700 text-[20px] flex items-center gap-2 rounded-[50px] cursor-pointer'><img src='/svg/playstore.svg' />Download Android</a>}
            {state?.projectLinkIOS && <a href={state?.projectLinkIOS} target='_blank' className='bg-[#FF5D22] px-8 py-4 text-white font-700 text-[20px] flex items-center gap-2 rounded-[50px] cursor-pointer'><img src='/images/IOS.png' className='w-[25px] h-[31px]' />Download IOs</a>}
            {state?.projectWebsiteLink && <a href={state?.projectWebsiteLink} target='_blank' className='bg-[#FF5D22] px-8 py-4 text-white font-700 text-[20px] flex items-center gap-2 rounded-[50px] cursor-pointer'>Go To Website</a>}
          </div>
          <ProjectDescription service={state} />
          <LanguageServices service={state} titleDes={'Features and Functionalities'} title={`Usability's`} />
          <ProjectServices service={state} />
          <ProjectProcess service={state} />
          {/* <ProjectResult service={state}/> */}
          {/* <ProjectTestimonials /> */}
          {/* <LanguageTeam service={project} /> */}
          <div className=' mt-[100px]'>
            <JoinUsForm />
          </div>
          <ContactUs />
        </div>
        {/* )
        })} */}
      </div>
    </div>
  )
}

export default ProjectDetailsPage

import React from 'react'

const LinkServices = ({ service }) => {
    return (
        <div data-aos="fade-right" className='flex justify-center gap-3 items-center my-[40px] text-[23px] font-[400] leading-[35px]'>
            <a href='/'> <img src='/svg/home.svg' className='cursor-pointer' /></a>
            <span >/</span>
            <a href='/services' className='text-[#FF5D22] font-[500] font-sans underline cursor-pointer'>Services</a>
            <span >/</span>
            <a href='' className='text-[#FF5D22] font-[500] font-sans underline cursor-pointer'>{service.title}</a>
        </div>
    )
}

export default LinkServices

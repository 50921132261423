import { message } from 'antd';
import { gapi } from 'gapi-script';
import React, { useEffect, useState } from 'react'
import commonConstants from '../../../utils/constants';
import ButtonComponent from '../../atoms/Button'
import styles from './style.module.scss'

const ApplyNowModal = ({ role, onCloseModal }) => {

    const CLIENT_ID = commonConstants.driveClientId;
    const API_KEY = commonConstants.driveAPIKey;
    const FOLDER_ID = commonConstants.driveFolderId;
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
    const SCOPES = "https://www.googleapis.com/auth/drive.file";

    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        Role: role?.label || '',
        Message: '',
        Resume: ''
    });

    const [loading, setLoading] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const onChangeForm = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    const setUploadedResume = (e) => {
        const file = e.target.files[0];

        if (file) {
            setFormData({
                ...formData,
                Resume: file
            })
        }
    };

    useEffect(() => {
        if (API_KEY && CLIENT_ID) {
            const initClient = () => {
                gapi.client.init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES
                }).then(() => {
                    gapi.auth2.getAuthInstance().isSignedIn.listen(afterSignIn);
                    afterSignIn(gapi.auth2.getAuthInstance().isSignedIn.get());
                });
            };
            gapi.load('client:auth2', initClient);
        }
    }, []);

    const handleAuthClick = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const afterSignIn = (isSignedIn) => {
        if (isSignedIn) {
            setIsSignedIn(true);
        }
        setLoading(false)
    };

    const submitForm = async (fileUrl) => {
        try {
            const sheetFormData = new FormData();

            sheetFormData.append("FirstName", formData?.FirstName);
            sheetFormData.append("LastName", formData?.LastName);
            sheetFormData.append("Email", formData?.Email);
            sheetFormData.append("PhoneNumber", formData?.PhoneNumber);
            sheetFormData.append("Message", formData?.Message);
            sheetFormData.append("Role", formData?.Role);
            sheetFormData.append("Resume", fileUrl);

            const res = await fetch(commonConstants.google_sheet_deployed_url, {
                method: "POST",
                body: sheetFormData
            });

            setLoading(false);
            setFormData({
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                Message: ''
            });

            if (res.status === 200) {
                message.success({
                    content: "Thank you for showing interest in The Coding Studio, We will contact you soon."
                })
                onCloseModal();

            }

            onCloseModal();

        } catch (error) {
            setLoading(false);
            message.error({
                content: error?.message || "Error in uploading your application, Please try again later."
            })
            console.log(error);
        }
        
    };

    const onSubmit = async (e) => {
        try {
            setLoading(true);

            e.preventDefault();

            if (FOLDER_ID && API_KEY && CLIENT_ID) {
                if (!isSignedIn) {
                    handleAuthClick();
                } else {
                    if (formData?.Resume && formData?.Resume !== "") {
                        const metadata = {
                            'name': formData?.Resume?.name,
                            'mimeType': formData?.Resume?.type,
                            parents: [FOLDER_ID]
                        };

                        const form = new FormData();
                        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
                        form.append('file', formData?.Resume);

                        const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + gapi.auth.getToken().access_token
                            },
                            body: form
                        });

                        const data = await response.json();
                        submitForm(`https://drive.google.com/file/d/${data?.id}/view`)
                    } else {
                        submitForm('')
                    }
                }
            } else {
                submitForm('')
            }

        } catch (error) {
            setLoading(false);
            message.error({
                content: error?.message || "Error in uploading your application, Please try again later."
            })
            console.log(error);
        }
    }

    const clearUploadedResume = () => {
        setFormData({
            ...formData,
            Resume: ''
        })
    }

    const isButtonDisabled = !formData.FirstName || !formData.LastName || !formData.Email || !formData.PhoneNumber;

    return (
        <div className='flex gap-10 flex-col justify-center items-center mt-10 p-2 md:p-4 pt-0'>
            <input value={""} id="uploadResumeInput" type="file" accept="*" multiple={false} onChange={setUploadedResume} style={{ display: 'none' }} />
            <div className='flex-col md:flex-row flex justify-between gap-6 w-[100%]'>
                <label className='flex flex-col gap-2 md:w-[48%]'>
                    <span className='text-[17px] leading-[21px] font-[400] font-sans'> First Name *</span>
                    <input value={formData?.FirstName} onChange={onChangeForm} className={`${styles.greyInput}`} type="text" name="FirstName" placeholder='First Name' />
                </label>
                <label className='flex flex-col gap-2 md:w-[48%]' >
                    <span className='text-[17px] leading-[21px] font-[400] font-sans'> Last Name *</span>
                    <input value={formData?.LastName} onChange={onChangeForm} className={`${styles.greyInput}`} type="text" name="LastName" placeholder='Last Name' />
                </label>
            </div>
            <div className='flex-col md:flex-row flex justify-between gap-6 w-[100%]'>
                <label className='flex flex-col gap-2 md:w-[48%]'>
                    <span className='text-[17px] leading-[21px] font-[400] font-sans'> Email ID *</span>
                    <input value={formData?.Email} onChange={onChangeForm} className={`${styles.greyInput}`} type="email" name="Email" placeholder='Email Id' />
                </label>
                <label className='flex flex-col gap-2 md:w-[48%]' >
                    <span className='text-[17px] leading-[21px] font-[400] font-sans'> Phone Number *</span>
                    <input value={formData?.PhoneNumber} onChange={onChangeForm} className={`${styles.greyInput}`} type="number" name="PhoneNumber" placeholder='Phone Number' />
                </label>
            </div>
            <div className='flex flex-col gap-2 w-[100%] '>
                <div className="text-[17px] leading-[21px] font-[400] font-sans">Role</div>
                <input value={formData?.Role} onChange={onChangeForm} disabled className={`${styles.greyInput}`} type="text" name="Role" placeholder='Role' />
            </div>
            <div className='flex flex-col gap-2 w-[100%] '>
                <div className="text-[17px] leading-[21px] font-[400] font-sans">Message</div>
                <textarea
                    onChange={onChangeForm}
                    value={formData?.Message}
                    id={''}
                    rows={3}
                    name="Message"
                    placeholder='Type here...'
                    className={`w-[100%] ${styles.greyTextArea}`}>
                </textarea>
            </div>
            {
                formData.Resume && formData.Resume !== "" ?
                    <div className='flex items-center w-full gap-4'>
                        <object className='w-[40px] h-[40px] border-2 border-gray-400 rounded-[50%]' data={URL.createObjectURL(formData.Resume)} aria-label='' />
                        <span className='text-[14px] font-[600] text-theme'>
                            {formData.Resume?.name}
                        </span>
                        <img className='h-[25px] cursor-pointer' src='/svg/cancel-theme.svg' onClick={clearUploadedResume} alt='cancel' />
                    </div>
                    :
                    <div className='flex items-center justify-center gap-3 cursor-pointer w-full h-[60px] border-dashed border-black border-2 rounded-[12px] bg-white' onClick={() => document.getElementById('uploadResumeInput').click()}>
                        <img src='/svg/upload-black.svg' alt='' />
                        <span className="text-[20px] leading-[24px] font-[500] font-sans">Upload Resume</span>
                    </div>
            }
            <ButtonComponent disabled={isButtonDisabled} loading={loading} labelClassname="text-[24px] leading-[24px] font-[500] font-sans" inputStyles={{ minWidth: '100%', width: '100%', height: '60px', boxShadow: 'none' }} label={"Submit"} onClick={onSubmit} />
        </div>
    )
}

export default ApplyNowModal
import { Select } from 'antd'
import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import commonConstants from '../../../utils/constants'
import ButtonComponent from '../../atoms/Button'
import styles from './style.module.scss';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { pathname } = location;
    const [dropdownVisible, setDropdownVisible] = useState(true);

    // const handleServiceClick = (e) => {
    //     e.preventDefault();
    //     setDropdownVisible(true);
    // };
    // const [headerStucked, setHeaderStucked] = useState(false);

    // useEffect(() => {
    //     function handleScroll() {
    //         const scrollPosition = window.scrollY;
    //         const stickyHeader = document.getElementById('stickyHeader');
    //         if (scrollPosition >= stickyHeader.offsetTop) {
    //             setHeaderStucked(true);
    //         } else {
    //             setHeaderStucked(false);
    //         }
    //     }

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const handleDropdownChange = (val) => {
        if (val.value === 'all-services') {
            navigate('/services');
        } else {
            navigate(`/services/${val.value}`);
        }
    }

    return (
        <div id='stickyHeader' className={`flex items-center z-[10] justify-between p-[25px] gap-4 sticky top-[0] transition-all duration-500 bg-[#F8F8F8] shadow-[0px_14px_54px_0px_#00000021] fixed w-full`}>
            <img src='/svg/logo.svg' className='w-[260px]' alt='Logo' onClick={() => { navigate('/') }} />
            <div className='max-w-[900px] gap-3 w-[100%] flex justify-evenly flex-wrap items-center'>
                {
                    commonConstants.headerItems.map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                {
                                    item.type === 'button' ?
                                        <ButtonComponent inputStyles={{ width: '140px', marginLeft: '20px' }} onClick={() => { navigate('/getInTouch') }} label={item.name} />
                                        :
                                        <div className='relative'>
                                            <div className='flex items-center dropdown-arrow gap-2'>
                                                {item.key === 'services' ? (
                                                    <>
                                                        <NavLink
                                                            to={item.path}
                                                            className={`${pathname === item.path ? 'flex items-center gap-2 text-theme font-[700] text-[20px]' : 'flex items-center gap-2 text-[#292929] text-[18px]'} font-[500] leading-[24px] hover:text-theme cursor-pointer`}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                        {dropdownVisible && (
                                                            <Select
                                                                value={(pathname || '').split('/').pop()}
                                                                popupClassName='dropdown-container'
                                                                suffixIcon={<img src='/svg/dropdown-arrow-black.svg' />}
                                                                placement='bottomRight'
                                                                onChange={handleDropdownChange}
                                                                options={commonConstants.headerServicesDropdownItems}
                                                                labelInValue={true}
                                                                labelRender={() => (<p className='hidden'></p>)}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <NavLink
                                                        to={item.path}
                                                        className={`${pathname === item.path ? 'text-theme font-[700] text-[20px]' : 'text-[#292929] text-[18px]'} font-[500] leading-[24px] hover:text-theme cursor-pointer`}
                                                    >
                                                        {item.name}
                                                    </NavLink>
                                                )}
                                            </div>
                                            {
                                                item.key == 'career' &&
                                                <div>
                                                    {/* <div className='absolute border-b-[1px] border-theme rotate-[158deg] right-[4px] w-[42%]' />
                                                    <div className='absolute border-b-[1px] border-theme rotate-[21deg] left-[7px] w-[42%]' /> */}
                                                    <div className='absolute right-[-25%] top-[90%] z-[1]'>
                                                        <div className={styles.hangingBoard}>
                                                            <div className={styles.board}>
                                                                <div className={styles.rope} />
                                                                <div className={styles.ropeLeftHang} />
                                                                <div className={styles.ropeRightHang} />
                                                            </div>
                                                            <div className={styles.text}>We are hiring</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Header
import React from 'react'

const Cancellation = () => {
    return (
        <div className='pt-[3%] pl-[5%] pr-[2%]'>
            <div className='mb-[15px]'>
                This Cancellation and Refund Policy outlines the terms and conditions for canceling orders and requesting refunds for purchases made through our website
                <a href='https://thecodingstudio.in/' className='text-[--themeColor]'>
                    thecodingstudio.in </a>. By making a purchase on our website, you agree to comply with this policy.
            </div>
            <div className='mb-[15px]'><span className='font-bold text-7px]'> 1. Order Cancellation </span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal' >
                <li><span className='font-semibold'>Order Cancellation by Customer:</span> You may cancel your order before it is processed or shipped by contacting our
                    customer service team at hr@thecodingstudio.in or
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:+917990345580`}>
                        +917990345580
                    </a>. Once an order is processed or shipped, it cannot be canceled.</li>
                <li><span className='font-semibold'>Order Cancellation by Us:</span> We reserve the right to cancel any order for reasons including but not limited to product availability issues, payment processing errors, or suspected fraud. In such cases,
                    we will notify you by email and issue a full refund if payment has already been processed.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 2. Refunds </span> </div>
            <div className='mb-[15px]'>Eligibility for Refunds : Refunds are available under the following conditions:</div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Defective or Damaged Products:</span> If you receive a defective or damaged product, you may request a refund or exchange within 7 days of receipt.
                    Please contact our customer service team with your order number and a description of the issue.</li>
                <li><span className='font-semibold'>Incorrect Items:</span> If you receive an item that is not what you ordered, please contact us within 7 days of receipt for a refund or exchange.</li>
                <li><span className='font-semibold'>Non-Refundable Items:</span> Certain items may be non-refundable due to their nature, such as digital downloads or personalized products.
                    These items will be clearly marked as non-refundable at the time of purchase.</li>

            </ul>

            <div className='mb-[15px]'>Refund Process: To request a refund, please follow these steps:</div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-disc'>
                <li>Contact our customer service team at hr@thecodingstudio.in or +917990345580 with your order number and reason for the refund request.</li>
                <li>If your request is approved, we will provide instructions for returning the product, if applicable.</li>
                <li>Refunds will be processed to the original payment method within [number] business days after we receive the returned product or approve the refund request.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 3. Payment Terms</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Payment Methods:</span> We accept [list payment methods, e.g., credit cards, debit cards, PayPal, etc.]. Payments are processed securely through our payment gateway.</li>
                <li><span className='font-semibold'>Billing:</span> You agree to provide accurate and complete payment information when making a purchase. You are responsible for any charges incurred under your account.</li>
                <li><span className='font-semibold'>Charges:</span>   All charges are in [Currency] and include applicable taxes and shipping fees, unless otherwise stated.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 4. Returns</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Return Process: </span> If you are eligible for a refund, you may be required to return the product to us. Please contact our customer service team for return instructions.
                    Products must be returned in their original packaging and condition.</li>
                <li><span className='font-semibold'>Shipping Costs:</span> You are responsible for any return shipping costs, unless the return is due to a defective or incorrect item.
                    In such cases, we will cover the return shipping costs.</li>
            </ul>

            <div className='mb-[15px]'><span className='font-bold text-7px]'> 5. Exchanges</span> </div>
            <ul className='mb-[15px] flex flex-col gap-[10px] list-decimal'>
                <li><span className='font-semibold'>Exchange Policy: </span>We may offer exchanges for defective or incorrect items.
                    To request an exchange, please contact our customer service team with your order number and the item you wish to receive in exchange.
                    Exchanges are subject to product availability.</li>
            </ul>


            <div className='mb-[15px]'><span className='font-bold text-7px]'> 6. Contact Us </span> </div>
            <div className='mb-[15px]'>For any questions or concerns regarding cancellations, refunds, or returns, please contact us at: </div>
            <ul className='mb-[55px] flex flex-col gap-[10px] list-disc'>
                <li><span className='font-semibold'>Email: </span>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`mailto:hr@thecodingstudio.in`}>
                        hr@thecodingstudio.in
                    </a></li>
                <li><span className='font-semibold'>Phone: </span>
                    <a className='font-[500] hover:text-theme cursor-pointer leading-[28px] md:leading-[33px] text-[14px] md:text-[16px]' href={`tel:+917990345580`}>
                        +917990345580
                    </a></li>
                <li><span className='font-semibold'>Address: </span>  436-437,The Boulevard, Near Pratham Circle, Gallaria Street Green city road , pal , Adajan, Surat, Gujarat 395009</li>
            </ul>

        </div>
    )
}

export default Cancellation
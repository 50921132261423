import React from 'react'
import UnderLineText from '../../atoms/UnderlineText'

const Intro = () => {
    return (
        <div className='relative flex flex-col gap-20 px-2 md:px-[20px] lg:px-[100px]' id='ele'>
            <img src='/svg/about-us-intro-bg.svg' className='hidden md:block md:absolute top-8 w-[50%] left-[10px] z-[0]' alt='intro-bg' />
            <UnderLineText text={'About us'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />

            <div className='flex flex-col-reverse md:flex-row items-start justify-between gap-3 relative z-[1]'>

                <div className='flex w-auto md:w-[40%] flex-col items-start gap-3'>
                    <span data-aos="fade-right" className='sub-title font-sans'>
                        TheCodingStudio
                    </span>
                    <span data-aos="fade-right" className='text-[18px] md:text-[20px] leading-[30px] md:leading-[32px] font-[400] font-sans'>
                        Our story is one of passion, innovation, and relentless pursuit of
                        excellence. Founded with a vision to revolutionize the software
                        industry, we embarked on a journey to create transformative
                        solutions that empower businesses worldwide.
                        <br />
                        <br />
                        Today, TheCodingStudio stands as a leading software developmen0t
                        company, known for our expertise, reliability, and customer-centric
                        approach.
                    </span>
                </div>
                <img data-aos="fade-left" className='w-[40%]' src='/svg/about-us-img.svg' alt='about-us' />
            </div>
            <div className='flex flex-col md:flex-row items-start gap-3 justify-between relative z-[1]'>
                <img data-aos="fade-right" className='w-[40%]' src='/svg/our-mission-img.svg' alt='our-mission' />
                <div className='flex w-auto md:w-[40%] flex-col items-start gap-2 md:gap-3'>
                    <UnderLineText animation={"fade-left"} text={'Our Mission'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
                    <span data-aos="fade-left" className='sub-title font-sans'>
                        TheCodingStudio
                    </span>
                    <span data-aos="fade-left" className='text-[18px] md:text-[20px] leading-[30px] md:leading-[32px] font-[400] font-sans'>
                        {/* Our mission is to deliver state-of-the-art technology solutions that drive efficiency, scalability, and profitability for startups and businesses.
                        <br /> */}
                        Our mission is to  to empower startups and businesses with cutting-edge technology solutions that drive efficiency, scalability, and profitability. We believe that technology should not only streamline operations but also serve as a catalyst for growth, enabling organizations to thrive in today's competitive landscape.
                        <br />
                        <br />
                        With a relentless focus on innovation and excellence, we work closely with our clients to understand their unique challenges and goals. By leveraging the latest advancements in technology, we craft bespoke solutions that deliver tangible results, empowering our clients to unlock new opportunities, accelerate growth, and achieve sustainable success.
                        <br />
                    </span>
                </div>
            </div>
            <div className='flex flex-col-reverse md:flex-row items-start gap-3 justify-between relative z-[1]'>
                <div className='flex w-auto md:w-[40%] flex-col items-start gap-3'>
                    <UnderLineText text={'Our Vision'} textClassname="text-[25px] leading-[32px] font-[400]" bgClassName="bg-right-bottom pr-[10px] bg-[length:100px_10px]" />
                    <span data-aos="fade-right" className='sub-title font-sans'>
                        TheCodingStudio
                    </span>
                    <span data-aos="fade-right" className='text-[16px] md:text-[20px] leading-[30px] md:leading-[32px] font-[400] font-sans'>
                        To be the leading provider of innovative and transformative tech solutions, empowering startups and businesses to achieve unparalleled growth and success through cutting-edge technology.
                    </span>
                </div>
                <img data-aos="fade-left" className='w-[40%]' src='/svg/our-vision-img.svg' alt='our-vision' />
            </div>
        </div>
    )
}

export default Intro